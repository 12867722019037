import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import PgHome from '@pages/PgHome';
import PgAuthentication from "@pages/public/PgAuthentication";
import PgRequestsPartners from '@subApps/requests/PgRequestsPartners';
import PgAdmin from "@subApps/admin/PgAdmin";
import PgUserAccount from "@subApps/account/PgUserAccount";

const RoutesProtected = () => {
  return (
    <Content>
      <Routes>
        <Route path='/' element={<Navigate replace to='/home' />} />
        <Route path='/home' element={<PgHome />} />
        <Route path='/user/:action' element={<PgAuthentication />} />
        <Route path='/requests/:view' element={<PgRequestsPartners />} />
        <Route path='/requests/:view/:id' element={<PgRequestsPartners />} />
        <Route path='/admin/:tab' element={<PgAdmin />} />
        <Route path='/account/:view' element={<PgUserAccount />} />
        <Route path='/*' element={<Navigate replace to='/' />} />
        <Route path='*' element={<Navigate replace to='/' />} />
        {/* handle 404 issue on Amazon S3-Hosted */}
      </Routes>
    </Content>
  );
};

export default RoutesProtected;

const Content = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  left: 50px;
  width: calc(100vw - 50px);
  height: 100vh;
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;
