import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import FulfillmentCompleteIcon from "@as_core/icons/fulfillmentComplete.icon";
import {UserT} from "@as_core/contexts/UserContext";
import CenteredMessages, {MessageItem} from "@as_core/elements/CenteredMessages";
import FulfillmentUnassignedIcon from "@as_core/icons/fulfillmentUnassigned.icon";
import DashboardIcon from "@as_core/icons/dashboard.icon";
import TableIcon from "@as_core/icons/table.icon";

export const ordersMenuItemsAdmin: MenuItemsT[] = [
  {
    id: 'reqUnassigned',
    name: 'Unassigned Requests',
    pathname: '/requests/unassigned',
    icon: <FulfillmentUnassignedIcon />
  },
]

export const ordersMenuItems: MenuItemsT[] = [
  {
    id: 'reqDashboard',
    name: 'Dashboard',
    pathname: '/requests/dashboard',
    icon: <DashboardIcon />
  },
  {
    id: 'reqTable',
    name: 'Table View',
    pathname: '/requests/table',
    icon: <TableIcon />
  },
  {
    id: 'reqComplete',
    name: 'Complete',
    pathname: '/requests/complete',
    icon: <FulfillmentCompleteIcon />
  },
];

const debug = false;
const ADMIN_ROLES = ['aseda-admin', 'aseda-dev', 'aseda-partners'];
export function hasRequiredRole(userRoles: string[], requiredRoles: string[]) {
  return userRoles.some((r) => requiredRoles.includes(r));
}

export function isRequestsAuthorized(user: UserT) {
  return hasRequiredRole(user.authRoles, ADMIN_ROLES);
}

// TODO - DHR -- log event when these errors happen -- send alert?

export const NotPartnersCognitoMessage = () => {
  return (
    <CenteredMessages
      messages={[
        <MessageItem key={'msg_no_authorization'} color={'accent'}>Not Authorized to Access this Tool/Functionality.</MessageItem>,
        <MessageItem key={'msg_no_authorization_submit_report'} color={'accent'}>If you should be authorized, please submit a bug report.</MessageItem>,
        <MessageItem key={'msg_no_authorization_reason'}>Not added to 'aseda-partners' or 'aseda-admin' role.</MessageItem>
      ]}
    />
  )
}

export const NoAuthorizationRecordsMessage = () => {
  return (
    <CenteredMessages
      messages={[
        <MessageItem key={'msg_no_authorization'} color={'accent'}>Incorrect Configuration.</MessageItem>,
        <MessageItem key={'msg_no_authorization_submit_report'} color={'accent'}>Please submit a bug report with the error below to authorize access to your lab requests.</MessageItem>,
        <MessageItem key={'msg_no_authorization_reason'}>Error: No partner authorization records in database.</MessageItem>
      ]}
    />
  )
}

export const getRequestsMenus = (user: UserT, subscription: string) => {
  if (debug) console.log('getMenuAccount | {user, subscription}:', user, subscription);
  let menus: MenuItemsT[] = [];
  if (user.authRoles.includes('aseda-admin') || user.authRoles.includes('aseda-dev')) menus = [...menus, ...ordersMenuItemsAdmin]
  if (isRequestsAuthorized(user)) menus = [...menus, ...ordersMenuItems];
  if (debug) console.log('getMenuAccount | {menus}:', menus);
  return menus;
}
