import {AxiosRequestConfig, AxiosResponse} from "axios";
import {authServicesClient} from "@utils/api/base";
import {processAxiosResponse} from '@utils/api/utils';
import useCognito from "@as_core/account/useCognito";

// the api settings
export const RequestForms = {
  all: (token: string, config: AxiosRequestConfig) => authServicesClient(token).get('/request_forms', config),
  byRequestId: (token: string, requestId: number) => authServicesClient(token).get(`/request_forms/request_id/${requestId}`),
  byRequestIdOfType: (token: string, requestId: number, formType: string) => authServicesClient(token).get(`/request_forms/request_id/${requestId}/${formType}`),
  get: (token: string, requestFormId: number) => authServicesClient(token).get(`/request_forms/${requestFormId}`),
  getFile: (token: string, requestFormId: number) => authServicesClient(token).get(`/request_forms/${requestFormId}/file`,
    {responseType: "blob"}),
  create: (token: string, config: AxiosRequestConfig) => authServicesClient(token).post('/request_forms', config),
  createWithFile: (token: string, config: AxiosRequestConfig) =>
    authServicesClient(token).post('/request_forms/with_file', config, {headers: {'Content-Type': 'multipart/form-data'}}),
  update: (token: string, id: number, config: AxiosRequestConfig) => authServicesClient(token).patch(`/request_forms/${id}`, config),
  delete: (token: string, id: number) => authServicesClient(token).delete(`/request_forms/${id}`),
};

// the Request Object Type
export type RequestFormT = {
  id: number;
  name: string;
  value: string;
  form_type: string;
  file_location: string;
  file_name: string;
  original_file_name: string;
  request_id: string;
}

const useRequestForms = () => {
  const { getToken } = useCognito();

  const getSortedForms = (tag: string, response: AxiosResponse<RequestFormT[]>): RequestFormT[] => {
    const forms: RequestFormT[] = processAxiosResponse(tag, response);
    forms.sort((a, b) => b.id - a.id);
    return forms;
  }

  const getRequestForms = async (params: {[key:string]: string}): Promise<RequestFormT[]> => {
    const config: AxiosRequestConfig = { params };
    let resp: AxiosResponse<RequestFormT[]> = await RequestForms.all(getToken(), config);
    return getSortedForms('getRequestForms', resp);
  };

  const getRequestFormsByRequestId = async (requestId: number): Promise<RequestFormT[]> => {
    let resp: AxiosResponse<RequestFormT[]> = await RequestForms.byRequestId(getToken(), requestId);
    return getSortedForms('getRequestFormsByRequestId', resp);
  };

  const getRequestFormsByRequestIdOfType = async (requestId: number, formType: string): Promise<RequestFormT[]> => {
    let resp: AxiosResponse<RequestFormT[]> = await RequestForms.byRequestIdOfType(getToken(), requestId, formType);
    return getSortedForms('getRequestFormsByRequestId', resp);
  };

  const getRequestForm = async (requestFormId: number): Promise<RequestFormT> => {
    let resp: AxiosResponse<RequestFormT> = await RequestForms.get(getToken(), requestFormId);
    return processAxiosResponse('getRequestForm', resp);
  };

  const getRequestFormFile = async (requestFormId: number): Promise<File> => {
    const resp: AxiosResponse<File> = await RequestForms.getFile(getToken(), requestFormId);
    return processAxiosResponse('getRequestFormFile', resp);
  };

  const createRequestForm = async (params: {[key:string]: string }): Promise<RequestFormT> => {
    const config: AxiosRequestConfig = params;
    const resp: AxiosResponse<RequestFormT> = await RequestForms.create(getToken(), config);
    return processAxiosResponse('getRequestFormFile', resp);
  };

  // Need to do multiple-part form  TODO -- set correct type both for FormData and AxiosRequestConfig
  // eslint-disable-next-line
  const createRequestFormWithFile = async (params: any): Promise<RequestFormT> => {
    const config: AxiosRequestConfig = params;
    const resp: AxiosResponse<RequestFormT> = await RequestForms.createWithFile(getToken(), config);
    return processAxiosResponse('createRequestFormWithFile', resp);
  };

  const updateRequestForm = async (requestId: number, updates: {[key: string]: string}): Promise<RequestFormT> => {
    const config: AxiosRequestConfig = updates;
    let resp: AxiosResponse<RequestFormT> = await RequestForms.update(getToken(), requestId, config);
    return processAxiosResponse('updateRequestForm', resp);
  };

  const deleteRequestForm = async (requestId: number): Promise<void> => {
    const resp: AxiosResponse<RequestFormT> = await RequestForms.delete(getToken(), requestId);
    const data = processAxiosResponse('deleteRequestForm', resp);
    if (data === null) return null;  // API return null if deleted. error if not.
    if (Object.hasOwn(data, 'detail')) {
      console.error('error in deleteRequestForm', data['detail']);
    } else {
      console.error('error in deleteRequestForm', data);
    }
    return null;
  };

  return {
    getRequestForms,
    getRequestFormsByRequestId,
    getRequestFormsByRequestIdOfType,
    getRequestForm,
    getRequestFormFile,
    createRequestForm,
    createRequestFormWithFile,
    updateRequestForm,
    deleteRequestForm
  };
};

export default useRequestForms;
