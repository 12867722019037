import React from "react";
import styled from "styled-components/macro";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import {UserT} from "@as_core/contexts/UserContext";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { Tune } from "@mui/icons-material";
import {PartnerT} from "@subApps/requests/hooks/usePartners";
import Partners from "./Partners";
import Authorizations from "./Authorizations";
import SettingsInfo from "./SettingsInfo";

export const adminMenuItems: MenuItemsT[] = [
  {
    id: 'a.partners',
    name: 'Partners',
    pathname: '/admin/partners',
    icon:  <GroupsOutlinedIcon />
  },
  {
    id: 'a.authorizations',
    name: 'Partner Authorizations',
    pathname: '/admin/authorizations',
    icon:  <Tune />
  },
  {
    id: 'a.settings',
    name: 'API Information',
    pathname: '/admin/settings',
    icon: <SettingsIcon />
  }
];

const debug = false;

export const getComponent = (tab: string, partners:PartnerT[], codeToName: {[key:string]: string} ) => {
  if (debug) console.log('tab', tab);
  switch (tab) {
    case 'partners':
      return(<Partners partners={partners} codeToName={codeToName} />)
    case 'authorizations':
      return (<Authorizations codeToName={codeToName} />)
    case 'settings':
      return (<SettingsInfo />)
  }
  return (
    <ErrorContainer>
      menu {tab} appears not to be implemented.
    </ErrorContainer>
  );
}

export const getAdminMenus = (user: UserT, subscription: string) => {
  if (debug) console.log('getMenuAdmin | subscription', subscription);
  return adminMenuItems;
}

export const ErrorContainer = styled.div`
  align-items: center;
`;


