import React, {useState} from 'react';
import styled from 'styled-components/macro';
import ErrorMessages, {MessageItem} from '@as_core/elements/UserMessages';
import {UserEmailDictionaryT} from '../hooks/useUserEmails';
import useRequests, { RequestT } from '../hooks/useRequests';
import CategoryContainer from "@subApps/requests/common/CategoryContainer";
import RequestStageTiles from "@subApps/requests/partners/RequestStageTiles";


interface PropsI {
  requestType?: string;
  requests: RequestT[];
  handleClick: (t: string, id: number) => void;
  userEmails: UserEmailDictionaryT;
}

const debug = false;
const RequestsTiles = (props: PropsI) => {
  const { requestType = 'Screen', requests, handleClick, userEmails } = props;
  const { getRequestStages, filterRequestsByStatus } = useRequests();
  const [stages] = useState<string[]>(getRequestStages(requestType))
  debug && console.log('partners/RequestsTable | {requests}:', requests);

  return (
    <>
      { requests.length ?
        <RequestTileContainer>
          {
            stages.map((stage, index) => {
              const stageRequests = filterRequestsByStatus(stage, requests);
              if (stageRequests.length) {
                return (
                  <CategoryContainer key={`${stage}_${index}`} title={`${stage} (${stageRequests.length})`}>
                    <RequestStageTiles
                      stage={stage}
                      requests={stageRequests}
                      handleClick={handleClick}
                      userEmails={userEmails}
                    />
                  </CategoryContainer>
                )
              }
            })
          }

        </RequestTileContainer>
        :
        <ErrorMessages messages={[
          <MessageItem key='emi_no_requests'>You do not have any open requests</MessageItem>
        ]}/>
      }
    </>
  );
};

export default RequestsTiles;

const RequestTileContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

