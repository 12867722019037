import React, {useState} from 'react';
import TextButton from "@as_core/controls/buttons/TextButton";
import styled from 'styled-components/macro';
import {Row} from '../../common/flexStyles';
import { RequestT } from '../../hooks/useRequests';
import RequestFormFiles from "../../files/RequestFormFiles";

type PropsT = {
  request: RequestT;
  // eslint-disable-next-line
  handleUpdate?: (id: number, updates: any) => void;
  handleCancel?: () => void;
}

const debug = false;
export const UploadData = (props:PropsT) => {
  const { request, handleUpdate} = props;
  const [numberForms, setNumberForms] = useState<number>(0);
  const [isAssayComplete, setIsAssayComplete] = useState<boolean>(false);

  if (debug) console.log('UploadData | request:', request);

  const handleConfirm = () => {
    handleUpdate(request.id, {status: 'Loading'});
    setIsAssayComplete(true);
  };

  if (debug) console.log('UploadData | request', request, ' numberForms', numberForms);

  return (
    <Container key={'upload-data-form'}>
      <Row>
        <RequestFormFiles
          viewMode={'partner'}
          formType={'assay_data'}
          formName={'Assay Data'}
          allowEdit={!isAssayComplete}
          request={request}
          setNumberForms={setNumberForms}
          height={'180px'}
          border={true}
        />
      </Row>
      <Row padding={'8px 0'}>
        <TextButton
          width={300}
          label={'Confirm All Data is Generated'}
          isDisabled={numberForms === 0}
          onClick={handleConfirm}
        />
      </Row>
    </Container>
  );
};

export default UploadData;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
`;
