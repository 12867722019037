import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import DataLoading from "@as_core/elements/DataLoading";
import useRequests, {RequestT, RequestUpdateT} from "./hooks/useRequests";
import useUserEmails from "./hooks/useUserEmails";
import RequestsTable from "./partners/RequestsTable";
import DraggableModal from "@as_core/elements/DraggableModal";
import UpdateRequest, {
  getModalHeight,
  getModalWidth,
  getRequestUpdateModalTitle
} from "@subApps/requests/forms/UpdateRequest";
import {useNavigate, useParams} from "react-router-dom";
import RequestDetails from "@subApps/requests/partners/RequestDetails";
import {getRequestById} from "@subApps/requests/Requests";

function getRequestDateToNumber(date: string): number {
  return Number(date.replace(/[T:\-]/g, ''));
}

const debug = false;
const RequestsUnassigned = () => {
  const view = 'unassigned';
  const { id: requestId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [userEmails, setUserEmails] = useState<{[key: string]: string }>({});
  const [emailRefreshKey, setEmailRefreshKey] = useState<number>(0);
  const { getUnassignedRequests, updateRequest } = useRequests();
  const { getUserEmails } = useUserEmails();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editRequest, setEditRequest] = useState<RequestT>(null);

  const getSortedRequests = async(): Promise<RequestT[]> => {
    const requests = await getUnassignedRequests();
    return requests.sort((a: RequestT, b: RequestT) =>
      getRequestDateToNumber(b.created_at) - getRequestDateToNumber(a.created_at));
  }

  // load requests for the specific defined action
  const { isPending, error, data: requests } = useQuery({
    queryKey: ['requests', 'unassigned'],
    queryFn: () => getSortedRequests()
  });

  const handleRequestUpdate = async (id: number, updates: RequestUpdateT) => {
    if (debug) console.log('partners/RequestsTable | handleRequestUpdate {id, updates}:', id, updates);
    mutation.mutate({id: id, updates: updates});
  }

  const handleClick = (type: string, id: number) => {
    debug && console.log('handleClick ', type, id);
    const matched = requests.find((r) => r.id === id);
    if (matched) {
      debug && console.log('handleEditClick: matched', matched);
      switch (type) {
        case 'edit':
          setEditRequest(matched);
          setIsModalOpen(true);
          break;
        case 'view':
          navigate(`/requests/${view}/${matched.id}`);
          break;
        default:
          console.error('Requests.handleClick | unknown type:', type);
      }
    }
  };

  const mutation = useMutation({
    mutationFn: (param: {id: number, updates: RequestUpdateT}) => updateRequest(param.id, param.updates),
    onSuccess: (data) => {
      if (debug) console.log('partners/RequestsTable | mutation | OnSuccess | data:', data);
      queryClient.invalidateQueries({ queryKey: ['requests', 'unassigned']}).then();
    }
  });

  // get user email dictionary for requests -- this uses a cached function to reduce calls
  useEffect(() => {
    if (requests) {
      const ids = requests.map((r) => r.user_id);
      getUserEmails(ids).then((newEmailDict) => {
        if (debug) console.log('Requests | getUserEMails | newEmailDict:', newEmailDict);
        setUserEmails(prevState => ({...prevState, ...newEmailDict}));
        // issue with useEffect not re-rendering after emailDict updated so using key to push refresh
        setEmailRefreshKey(prevState => prevState + 1);
      });
    }
  }, [requests]);

  debug && console.log('Requests | isPending', isPending, 'error', error, 'requests:', requests,
    'userEmails', userEmails);

  return (
    <RequestsContainer>
      {
        isPending ? <DataLoading />
      :
        <>
          {
            requestId ?
              <RequestDetails
                request={getRequestById(requests, Number(requestId))}
                handleRequestUpdate={handleRequestUpdate}
                userEmails={userEmails}
                handleEdit={() => handleClick('edit', Number(requestId))}
                handleBack={()=> navigate(`/requests/unassigned`)}
              />
              :
              <RequestsTable
                key={`action_${emailRefreshKey}`}
                action={'unassigned'}
                requests={requests}
                handleClick={handleClick}
                userEmails={userEmails}
              />
          }
          <DraggableModal
            title={getRequestUpdateModalTitle(editRequest, 'partners')}
            height={getModalHeight(editRequest)}
            width={getModalWidth(editRequest)}
            isModalOpen={isModalOpen}
            onCloseClick={()=>setIsModalOpen(false)}
          >
            <UpdateRequest
              viewMode={'partners'}
              request={editRequest}
              handleRequestUpdate={handleRequestUpdate}
              closeDialog={() => setIsModalOpen(false)}
            />
          </DraggableModal>
        </>
      }
    </RequestsContainer>
  );
};

export default RequestsUnassigned;

const RequestsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 10px;
  width: calc(100vw - 50px);
  justify-content: center;
  overflow-x: auto;
`

