import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';

import SingleSelect, {getSelectOptions} from "@as_core/controls/inputs/SingleSelect";

interface SelectorDropdownProps {
  label: string;
  value: string;
  optionKey?: string;
  width?: number;
  options: {[key: string]: string}[];
  setValue: (v) => void;
  onClick?: () => void;
}
const debug = false;
const SelectorDropdown = (props:SelectorDropdownProps) => {
  const {label, optionKey='value', width=100, options, value, setValue} = props;
  if (debug) console.log('SelectorDropdown:', props);
  const [selectOptions, setSelectOptions] = useState(getSelectOptions(options, optionKey));

  function handleChange(v) {
    // console.log('handleChange', v.target.value);
    setValue(v.target.value);
  }

  useEffect(()=>{
    setSelectOptions(getSelectOptions(options, optionKey))
  }, [options])

  return (
    <SelectorContainer width={width}>
      <SingleSelect
        id={label}
        label={label}
        value={value}
        options={selectOptions}
        onChange={handleChange}
        onClick={props?.onClick ? props.onClick : ()=>{}}
        width={width}
      />
    </SelectorContainer>
  );
};

export default SelectorDropdown;

const SelectorContainer = styled.div<{width: number}>`
  display: flex;
`;

