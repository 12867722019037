import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';

export const AuthorizationFields: BasicTableFieldsT[] = [
    { value: 'edit', label: '', type: 'button', width: 40 },
    { value: 'authEmail', label: 'Partner Email', type: 'email', width: 300 },
    { value: 'authorizations', label: 'Authorizations', type: 'authorizations', width: 150 },
    { value: 'created', label: 'Created', type: 'datetime', width: 150 },
    { value: 'updated', label: 'Last Updated', type: 'datetime', width: 150 },
    { value: 'delete', label: '', type: 'button', width: 40 },
]

export const PartnerFields: BasicTableFieldsT[] = [
    { value: 'edit', label: '', type: 'button', width: 40 },
    { value: 'code', label: 'Partner Code', type: 'string', width: 100 },
    { value: 'name', label: 'Partner Name', type: 'string', width: 225 },
    { value: 'created', label: 'Created', type: 'datetime', width: 150 },
    { value: 'updated', label: 'Last Updated', type: 'datetime', width: 150 },
    { value: 'delete', label: '', type: 'button', width: 40 },
]
