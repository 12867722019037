import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@as_core/contexts/UserContext';
import {PartnerT} from "@subApps/requests/hooks/usePartners";
import BasicTable from "@as_core/tables/BasicTable";
import MenuButton from "@as_core/elements/MenuButton";
import {AddItem} from "@as_core/icons/styledIcons";
import DraggableModal from "@as_core/elements/DraggableModal";
import {dataToTableRows} from "./utils/renderValues";
import { PartnerFields as cols } from "./utils/tableDefinitions";
import UpdatePartners from "./forms/UpdatePartners";

interface PropsI {
  partners: PartnerT[],
  codeToName: {[key: string]: string};
}

type UpdateTypesT = 'create' | 'update' | 'delete';

const modalSizes: {[key: string]: {height: number, width: number}} = {
  'create': {height: 275, width: 500},
  'update': {height: 250, width: 500},
  'delete': {height: 175, width: 500}
}

const debug = false;
const Partners = (props:PropsI) => {
  const { partners, codeToName } = props;
  const { user } = useContext(UserContext);
  const [rows, setRows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [updateType, setEditType] = useState<UpdateTypesT>('update');
  const [editPartner, setEditPartner] = useState<PartnerT>(null);

  if (debug) console.log('Partners | user:', user, ' codeToName: ', codeToName);

  // HANDLERS
  const handleClick = (type: UpdateTypesT, itemId:string) => {
    if (debug) console.log('Partners | handleClick {type, itemId}:', type, itemId);
    setEditType(type);
    setIsModalOpen(true);
    if (type !== 'create') {
      setEditPartner(partners.find((p) => p.id === itemId))
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditPartner(null);
  }

  useEffect(() => {
    if (partners !== undefined) {
      setRows(dataToTableRows(partners, cols, codeToName, handleClick));
    }
  }, [partners]);

  if (debug) console.log('Partners | partners:', partners, 'rows:', rows);

  return (
    <Container>
      <MenuButton
        text={''}
        icon={<AddItem />}
        onClick={() => handleClick('create', '')}
      />
      <BasicTable
        fields={cols}
        rows={rows}
        border={false}
      />
      <DraggableModal
        title={updateType === 'create' ? 'Add a New Partner' : updateType === 'delete' ? 'Delete a Partner Record': 'Edit a Partner\'s Information'}
        height={Object.hasOwn(modalSizes, updateType) ? modalSizes[updateType].height : 400}
        width={Object.hasOwn(modalSizes, updateType) ? modalSizes[updateType].width : 500}
        isModalOpen={isModalOpen}
        onCloseClick={handleCancel}
      >
        <UpdatePartners
          updateType={updateType}
          partner={editPartner}
          closeDialog={handleCancel}
        />
      </DraggableModal>
    </Container>
  );
};

export default Partners;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
