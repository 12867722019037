import React from 'react';
import IconHome from '@components/icons/IconHome';
import { getAdminMenus } from '@subApps/admin/adminMenus';
import { getAccountMenus } from '@subApps/account/accountMenus';
import { getRequestsMenus } from "@subApps/requests/requestsMenus";
import {UserT} from "@as_core/contexts/UserContext";
import { MenuItemsT } from '@as_core/menus/SideMenu';

export const homeMenu:MenuItemsT = {
  id: 'home',
  name: 'Home',
  pathname: '/home',
  icon: <IconHome />,
}

type getMenuT = (user:UserT, subscription: string) => MenuItemsT[];
export const menuFunctions: {[key: string]: getMenuT} = {
  'Admin': getAdminMenus,
  'Account': getAccountMenus,
  'Requests': getRequestsMenus
};