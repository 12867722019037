import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {Label, Row} from "@subApps/requests/common/flexStyles";
import {FlexItem} from "@as_core/elements/flexStyles";
import TextButton from "@as_core/controls/buttons/TextButton";
import InputField from "@as_core/components/inputs/InputField";
import MultiSelectDropdown from "@as_core/controls/inputs/MultiSelectDropdown";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import usePartners, {PartnerAuthorizationT} from "@subApps/requests/hooks/usePartners";

type AuthorizationsCreateT = {
  authEmail: string;
  authorizations: string[];
}

type OptionT = {
  value: string;
  label: string;
}

type PropsT = {
  updateType: string;
  authorization?: PartnerAuthorizationT;
  codeToName: {[key: string]: string};
  closeDialog?: () => void;
}

const getSelectOptions = (codeToName: {[key: string]: string}): OptionT[] => {
  return Object.keys(codeToName).map((key: string) => ({value: key, label: codeToName[key]}));
}

const isDisabled = (editType: string, authorization: PartnerAuthorizationT, updates: AuthorizationsCreateT) => {
  if (editType === 'delete') return false;
  if (editType === 'create') {
    return updates['authEmail'] === '' || updates['authorizations'].length === 0;
  }
  if (updates['authorizations'].length !== authorization.authorizations.length) return false;
  authorization.authorizations.forEach((code) => {
    if (!updates['authorizations'].includes(code)) return false;
  })
  return true;
}

const debug = false;
const UpdateAuthorizations = (props:PropsT) => {
  const {updateType, authorization=null, codeToName} = props;
  const [selectOptions, setSelectOptions] = useState<OptionT[]>(getSelectOptions(codeToName));
  const queryClient = useQueryClient();
  const {createPartnerAuthorization, updatePartnerAuthorization, deletePartnerAuthorization} = usePartners();
  const [updates, setUpdates] = useState<AuthorizationsCreateT>({
    'authEmail': '',
    'authorizations': authorization===null ? [] : authorization.authorizations,
  });
  debug && console.log('UpdateAuthorizations | updateType: ', updateType, ' authorization:', authorization, ' selectOptions:', selectOptions);

  const handleAPICall = async (id: string, updates: AuthorizationsCreateT) => {
    switch (updateType) {
      case 'create':
        return createPartnerAuthorization(updates)
      case 'update':
        return updatePartnerAuthorization(id, updates);
      case 'delete':
        return deletePartnerAuthorization(id);
    }
    console.error('UpdateAuthorizations | unknown update Type: ', updateType);
  }

  const mutation = useMutation({
    mutationFn: (param: {id: string, updates: AuthorizationsCreateT}) => handleAPICall(param.id, param.updates),
    onSuccess: (data) => {
      if (debug) console.log('UpdateAuthorizations | mutation | OnSuccess | data:', data);
      props?.closeDialog && props.closeDialog();
      queryClient.invalidateQueries({ queryKey: ['authorizations', 'all'] }).then();
    }
  });

  const handleSubmit = async () => {
    const id = authorization === null ? null : authorization.id;
    if (debug) console.log('UpdateAuthorizations | handleSubmit {authorization}:', id, updates);
    mutation.mutate({id: id, updates: updates});
  }

  const _handleFieldUpdate = (field:string, value: string | string[]) => {
    if (debug) console.log('_handleFieldUpdate', field, value);
    setUpdates((prev) => ({...prev, [field]: value }));
  }

  const handleCancel = () => {
    if (debug) console.log('UpdateAuthorizations.handleCancel | Cancel Clicked');
    props?.closeDialog && props.closeDialog();
  }

  function handleMultiSelectClick(updateType: string, id: string) {
    if (debug) console.log('UpdateAuthorizations.handleMultiSelect | updateType:', updateType, id);
    if (updateType === 'add') {
      const newValues = [...updates['authorizations'], id]
      _handleFieldUpdate('authorizations',newValues);
    } else if (updateType === 'remove') {
      const newValues = updates['authorizations'].filter((v) => (v !== id));
      _handleFieldUpdate('authorizations',newValues);
    } else if (updateType === 'reset') {
      _handleFieldUpdate('authorizations', []);
    } else {
      console.error('UpdateAuthorizations | unknown update Type: ', updateType);
    }
  }

  useEffect(() => {
    if (codeToName !== undefined) {
      setSelectOptions(getSelectOptions(codeToName))
    }
  }, [codeToName])

  const disabled = isDisabled(updateType, authorization, updates)

  return (
    <UpdateAuthorizationsContainer>
      { authorization === null && (
        <Row h_align={'flex-start'} v_align={'center'}>
          <Label width={'120px'}>Email:</Label>
          <FlexItem height='32px' width='273px'>
            <InputField
              id='update_auth_email'
              label=''
              value={Object.hasOwn(updates, 'authEmail') ? updates['authEmail'] : ''}
              onChange={(e) => _handleFieldUpdate('authEmail', e.target.value)}
            />
          </FlexItem>
        </Row>
      )}
      { updateType === 'delete' ?
        <Row h_align={'center'} v_align={'center'} width='100%' height = '80px'>
          <Label width={'max-content'}>Confirm Deleting Authorizations for {authorization.authEmail}</Label>
        </Row>
        :
        <Row h_align={'flex-start'} v_align={'center'} height='125px'>
          <Label width={'120px'}>Authorizations:</Label>
          <FlexItem height={'32px'}>
            <MultiSelectDropdown
              id='update_authorizations'
              label=''
              values={selectOptions}
              selectedIds={updates['authorizations']}
              maxAllowed={20}
              onSelect={(id) => handleMultiSelectClick('add', id)}
              onUnselect={(id) => handleMultiSelectClick('remove', id)}
              onReset={() => handleMultiSelectClick('reset', null)}
            />
          </FlexItem>
        </Row>
      }
      <Row>
        <TextButton width={100} label={'Cancel'} onClick={() => handleCancel()} />
        <TextButton
          width={100}
          label={authorization === null ? 'Create' : updateType === 'delete' ? 'Delete' :'Save Changes'}
          isDisabled={disabled}
          onClick={disabled ? ()=>{} : () => handleSubmit()}
        />
      </Row>
    </UpdateAuthorizationsContainer>
  );
};

export default UpdateAuthorizations;

const UpdateAuthorizationsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
