import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import { TextTitle } from '@components/elements/TextStyles';
import useBugReports from './bugs.api';
import EditIcon from '@components/icons/edit.icon';
import MenuButton from '@components/controls/MenuButton';
import TrashIcon from '@components/icons/trash.icon';
import { APP_CONFIG } from '@app_config/app';
import BasicTable from '@as_core/tables/BasicTable';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import ViewIcon from '@as_core/icons/view.icon';
import { IBugsList, IBugReport } from './bugs.types';

const tableFields: BasicTableFieldsT[] = [
  { value: 'view', label: '', type: 'computed', width: 40 },
  { value: 'edit', label: '', type: 'computed', width: 40 },
  { value: 'title', label: 'Title', align: 'left', width: 300 },
  {
    value: 'submit.time_submitted',
    label: 'Date Submitted',
    type: 'date',
    width: 150,
  },
  { value: 'status', label: 'Status', width: 100 },
  { value: 'delete', label: '', type: 'computed', width: 40 },
];

const BugsList = ({
  isNewBugReported,
  onActionBug,
  inEditBug,
  deletedBugId,
  setDeletedBugId,
}: IBugsList) => {
  const { getBugReports } = useBugReports();
  const [bugReports, setBugReports] = useState<IBugReport[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getBugReports({ system: APP_CONFIG.name, bug_type: 'all' });
      setBugReports((response?.data as IBugReport[]) || []);
    };
    fetchData();
  }, [isNewBugReported]);

  useEffect(() => {
    if (deletedBugId) {
      setBugReports((prev) => prev.filter((bug) => bug.uuid !== deletedBugId));
      setDeletedBugId();
    }
  }, [deletedBugId]);

  const handleClick = (id: string, actionType: string) => {
    onActionBug(
      bugReports.find((report) => report._id === id),
      actionType
    );
  };

  const getTableRows = useMemo(
    () =>
      bugReports.map((bugReport) => ({
        ...tableFields.reduce((row, field) => {
          const value = _.get(bugReport, field.value, '');

          if (field.type === 'date' && value) {
            row[field.value] = new Date(value).toDateString();
          } else if (field.value === 'view') {
            row[field.value] = (
              <MenuButton
                onClick={() => handleClick(bugReport._id, 'view')}
                icon={<ViewIcon />}
                text='View Issue/Request'
                tooltipPlacement='bottom'
              />
            );
          } else if (field.value === 'edit') {
            row[field.value] = (
              <MenuButton
                onClick={() => handleClick(bugReport._id, 'edit')}
                icon={<EditIcon />}
                text='Edit Issue/Request'
                tooltipPlacement='bottom'
                isActive={inEditBug?._id === bugReport._id}
              />
            );
          } else if (field.value === 'delete') {
            row[field.value] = (
              <MenuButton
                onClick={() => handleClick(bugReport._id, 'delete')}
                icon={<TrashIcon />}
                text='Delete Issue/Request'
                tooltipPlacement='bottom'
              />
            );
          } else {
            row[field.value] = value;
          }
          return row;
        }, {}),
      })),
    [bugReports, handleClick, inEditBug]
  );

  return (
    <Content>
      <Heading color='accent'>Your Currently Open Issues/Requests</Heading>
      {bugReports.length === 0 ? (
        <FlexRow width='100%' height='100%' h_centered>
          <FlexItem>
            <Heading color='primary'>
              You currently do not have any open issues/requests
            </Heading>
          </FlexItem>
        </FlexRow>
      ) : (
        <TableContainer>
          <ScrollBox>
            <BasicTable
              fields={tableFields}
              rows={getTableRows}
              border={false}
            />
          </ScrollBox>
        </TableContainer>
      )}
    </Content>
  );
};

export default BugsList;

const Content = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 225px;
  width: 100%;
`;

const ScrollBox = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

const Heading = styled(TextTitle)`
  width: 100%;
  font-size: 18px;
`;
