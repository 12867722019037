import React from 'react';
import { createRoot } from 'react-dom/client';
import { StyleProvider } from '@theme/AppStyles';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://58aecd231d36236a2e512ff4c480d879@o4508739797581824.ingest.us.sentry.io/4509071315894272',
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://3rnd-partners.asedasciences.com',
  ],
  // Session Replay
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const render = () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <StyleProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StyleProvider>
  );
};

render();
