import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { getValueByPath} from "@utils/misc";
import TextButton from "@as_core/controls/buttons/TextButton";
import { RequestT } from '../../hooks/useRequests';
import { Label, Row} from '../../common/flexStyles';
import { FlexItem } from '@as_core/elements/flexStyles';
import SingleSelect from "@as_core/controls/inputs/SingleSelect";
import usePartners from "../../hooks/usePartners";

type AssignUpdatesT = {
  'assigned_to': string
}

type PropsT = {
  request: RequestT;
  // eslint-disable-next-line
  handleUpdate?: (id: number, updates: any) => void;
  handleCancel?: () => void;
}

const debug = false;
export const Assign = (props:PropsT) => {
  const { request, handleUpdate} = props;
  if (debug) console.log('Assign | request', request);
  const { getVendorOptions } = usePartners();
  const [updates, setUpdates] = useState<AssignUpdatesT>({
    'assigned_to': getValueByPath(request, 'assigned_to', null)
  });

  const _handleUpdates = (field:string, value:string) => {
    debug && console.log(field, value);
    setUpdates((prev) => ({...prev, [field]: value }));
  }

  const handleCancel = () => {
    debug && console.log('UpdateRequest | Form Assign | Cancel Clicked', props?.handleCancel);
    props?.handleCancel && props.handleCancel();
  }

  debug && console.log('Assign | updates', updates, getVendorOptions(request));

  if (request === null || request === undefined) {
    return <>Undefined request</>;
  }
  const disabled = updates['assigned_to'] === null || updates['assigned_to'] === ''
    || updates['assigned_to'] === request['assigned_to'];

  return (
    <Container key={'request-assign-form'}>
      <Row h_align={'center'}>
        <Label width={'70px'}>Assign To:</Label>
        <FlexItem>
          <SingleSelect
            id='assigned_to_select'
            label=''
            width={200}
            value={Object.hasOwn(updates, 'assigned_to') ? updates['assigned_to'] : ''}
            options={getVendorOptions(request)}
            onChange={(e) => _handleUpdates('assigned_to', e.target.value)}
          />
        </FlexItem>
      </Row>
      <Row>
        <TextButton width={100} label={'Cancel'} onClick={() => handleCancel()} />
        <TextButton
          width={100}
          label={'Save Changes'}
          isDisabled={disabled}
          onClick={disabled ? ()=>{} : () => handleUpdate(request?.id, updates)}
        />
      </Row>
    </Container>
  );
};

export default Assign;

const Container = styled.div`
  width: max-content;
  max-width: 400px;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
`;