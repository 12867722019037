import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import HeaderTab, { actionT } from '@as_core/pages/HeaderTab';
import {PartnerT} from "@subApps/requests/hooks/usePartners";

function getMenuFromAuthorizations(authorizations: string[], partners: PartnerT[], handleAction: (v:string) => void): actionT[] {
  const newActionItems: actionT[] = [];
  authorizations.forEach((code) => {
    let label = code;
    const partner = partners ? partners.find((partner) => partner.code === code) : null;
    if (partner) label = partner.name;
    newActionItems.push({
      key: code,
      label: label,
      action: handleAction,
    })
  })
  return newActionItems;
}

type PropsT = {
  authorizations: string[];
  view: string;
  partners: PartnerT[];
  partnerCode: string;
  handleClick: (c:string) => void;
}
const debug = false;
const PartnerSelector = (props:PropsT) => {
  const { authorizations, view, partners, partnerCode, handleClick } = props;
  const [menuItems, setMenuItems] = useState<actionT[]>(getMenuFromAuthorizations(authorizations, partners, handleClick));

  if (debug) console.log('PartnerSelector | authorizations:', authorizations, 'partners', partners);

  useEffect(() => {
    setMenuItems(getMenuFromAuthorizations(authorizations, partners, handleClick));
  }, [authorizations, partners, view]);

  return (
    <>
      { menuItems.length ?
        <HeaderTab
          headerActions={menuItems}
          selectedTab={partnerCode}
        />
        :
        <HeaderMessage>
          Not authorizations available -- submit bug report.
        </HeaderMessage>
      }
    </>

  )
}

export default PartnerSelector;

const HeaderMessage = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  padding: 0;
  height: 32px;
`;
