import React from 'react';
import styled from 'styled-components/macro';
import { useApiInfo } from '@utils/apiInfo';
import ShowObject from '@as_core/elements/ShowObject';
import DataLoading from '@as_core/elements/DataLoading';
import {useQuery} from "@tanstack/react-query";

const debug = false;
const SettingsInfo = () => {
  const { getApiInfoAll } = useApiInfo();

  const { isPending, error, isError, data: apiInfo } = useQuery({
    queryKey: ['apiInfo', 'all'],
    queryFn: () => getApiInfoAll()
  });
  if (isError) console.error('SettingsInfo.getApiInfoAll | error', error.message);

  if (debug) console.log('SettingsInfo | apiInfo', apiInfo);

  return (
    <Container>
      { !isPending ?
        <>
        { Object.keys(apiInfo).map((key: string) => (
          <Tile key={key}>
            <Title>{key} API: </Title>
            <Values><ShowObject obj={apiInfo[key]?.api}/></Values>
            <Title>{key} DB: </Title>
            <Values><ShowObject obj={apiInfo[key]?.db}/></Values>
          </Tile>
        ))}
        </>
      :
        <DataLoading />
      }
    </Container>
  );
};

export default SettingsInfo;

const Container = styled.div`
  display: flex;
  padding: 10px;
  max-width: calc(100vw - 50px);
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  height: max-content;
  width: max-content;
`;

const Tile = styled.div`
  width: 500px;
  min-height: 100px;
  padding: 10px;
  margin: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary};
`

const Title = styled.div`
    display: flex;
`;

const Values = styled.div`
  display: flex;
`;

