import React from 'react';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HoverTextTruncate from '@as_core/elements/HoverTextTruncate';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import { VENDOR_MASK } from '../hooks/usePartners';
import { getValueByPath, truncateString } from '@utils/misc';
import TextButton from '@as_core/controls/buttons/TextButton';
import MenuButton from '@as_core/elements/MenuButton';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import ViewIcon from '@as_core/icons/view.icon';
import { RequestT } from '../hooks/useRequests';
import { DisplayField, Label, Value } from '../common/flexStyles';
import {OrderT} from "../orders/types";
import {FlexColumn, FlexItem} from "@as_core/elements/flexStyles";
import {TextSmallBold} from "@as_core/elements/textStyles";

// TODO -- Clean up these functions - DHR 2025-01-18

export const getRequestDetailsDisplayValue = (
request: RequestT,
item: BasicTableFieldsT,
index: number,
userEmails: {[key: string]: string},
width: string) => {
  if (item.type === 'email') {
    const value = (Object.hasOwn(userEmails, request.user_id) ? userEmails[request.user_id] : request.user_id);
    return (
    <DisplayField key={index}>
      <Label>{item.label}:</Label>
      <Value width={width}>{value}</Value>
    </DisplayField>
    );
  }
  return (
  <DisplayField key={index}>
    <Label>{item.label}:</Label>
    <Value width={width}>{getFieldValue(request, item)}</Value>
  </DisplayField>
  );
};

export function getShowDate (stringDate: string, format:string = 'date'):string  {
  if (!stringDate) return '';
  const utcDate = new Date(stringDate)
  if (format === 'datetime')  return utcDate.toLocaleDateString() + ' ' + utcDate.toLocaleTimeString();
  return utcDate.toLocaleDateString()
}

export const getStringHover = (text: string, width: number) => {
  if (typeof(text) !== 'string') return '';
  const characters = Math.floor(width/4);
  if (text.length < characters) return text;
  return(
  <Tooltip title={text}>
    { truncateString(text, characters)}
  </Tooltip>
  )
}

function getOrderLineQuantity(item: RequestT | OrderT): string {
  let quantity = getValueByPath(item, 'quantity', 0);
  let unit_size = getValueByPath(item, 'unit_value', '');
  if (unit_size === null || quantity === null) return '';
  const parts = unit_size.split(' ');
  if (parts.length > 1) {
    quantity = quantity * Number(parts[0]);
    unit_size = parts[1];
    if (quantity > 1) unit_size += 's';
  }
  return quantity.toString().concat(' ', unit_size);
}

export const getFieldValue = (
  item: RequestT | OrderT,
  field: BasicTableFieldsT
): string | number | React.ReactNode => {
  const value: string = getValueByPath(item, field.value, '');
  if (value === null) return '';
  if (field?.type) {
    if (['date', 'datetime'].includes(field.type)) {
      return getShowDate(value, field.type);
    } else if (field?.type && field.type === 'quantity') {
      return getOrderLineQuantity(item);
    } else if (field.type === 'vendor') {
      const value = getValueByPath(item, 'assigned_to', '');
      if (Object.hasOwn(VENDOR_MASK, value)) {
        return VENDOR_MASK[value];
      }
      return value;
    } else if (field.type === 'shortString') {
      return <HoverTextTruncate text={value} characters={15}/>;
    } else if (field.type === 'array_count') {
      if (Array.isArray(value)) return value.length;
      return 'unknown';
    }
  }
  return value;
}

export const getRequestStatusOrButton = (
  viewType: string,
  request: RequestT | OrderT,
  handleClick: (a:string, id:number) => void
) => {
  const status = request?.status;
  // console.log('viewType: ', viewType, ' status: ', status);
  const id = Object.hasOwn(request, 'id') ? request['id'] : null;
  const assigned_to = getValueByPath(request, 'assigned_to', '');
  if (status === 'Open' && viewType === 'partners') {
    // console.log('status: ', status, 'assigned_to', assigned_to);
    if (assigned_to === null || assigned_to === '') {
      return (
      <TextButton
        width={135}
        height={30}
        fontSize={12}
        margin={'2px'}
        label={'Assign Request'}
        onClick={() => handleClick('edit', id)}
      />
      );
    } else {
      return "Waiting on Customer";
    }
  } else if (status === 'Open' && viewType === 'user') {
    // console.log('status: ', status, 'assigned_to', assigned_to);
    if (assigned_to === null || assigned_to === '') {
      return "Processing";
    } else {
      return (
        <TextButton
        width={135}
        height={30}
        fontSize={12}
        margin={'2px'}
        label={'Action Required'}
        onClick={() => handleClick('edit', id)}
        />
      );
    }
  } else if (request?.itemType && request.itemType === 'subscription') {
    return 'Activated';
  }
  return status;
};

export const getTitleInfo = (
  request: RequestT | OrderT,
) => {
  return(
  <>
    {getValueByPath(request, 'name', '') + ' ' }
    <HoverInfoText text={getValueByPath(request, 'description', '')}/>
  </>
  )
}

export const getTitleCustomerInfo = (
  request: RequestT | OrderT,
) => {
  const user_id = getValueByPath(request, 'user_id', '');
  const user_email = getValueByPath(request, 'userEmail', '');
  const email = user_email !== '' ? user_email : user_id
  const title = getValueByPath(request, 'name', '')
  return(
    <FlexColumn width={'100%'}>
      <FlexItem><TextSmallBold color={'accent'}>{title}</TextSmallBold></FlexItem>
      <FlexItem>{email}</FlexItem>
    </FlexColumn>
  )
}

export const getProductQuantityInfo = (
  request: RequestT | OrderT,
) => {
  const quantity = getOrderLineQuantity(request);
  const name = getValueByPath(request, 'prod_name', '');
  return(
    <FlexColumn width={'100%'}>
      <FlexItem><TextSmallBold color={'accent'}>{name}</TextSmallBold></FlexItem>
      <FlexItem>{quantity}</FlexItem>
    </FlexColumn>
  )
}

// Return the display value for the column from the AssayRequestForms
export const getDisplayValue = (
  action: string,
  viewMode: string,
  col: BasicTableFieldsT,
  item: RequestT | OrderT,
  handleClick: (a:string, id:number) => void
) => {
  const id = Object.hasOwn(item, 'id') ? item['id'] : '';
  if (col.type === 'status' ) {
    return getRequestStatusOrButton(viewMode, item, handleClick);
  } else if (col.type === 'title') {
    return (getTitleInfo(item));
  } else if (col.type === 'email') {
    const user_id = getValueByPath(item, 'user_id', '');
    const user_email = getValueByPath(item, 'userEmail', '');
    if (user_email !== '') return user_email;
    return user_id;
  } else if (col.type === 'edit') {
    if (action === 'open') {
      if (getValueByPath(item, 'is_assigned', '') === '') {
        return (
        <MenuButton
          onClick={() => handleClick('edit', id)}
          icon={<EditIcon/>}
          text={'Adjust Assignment'}
          tooltipPlacement={'bottom'}
        />
        )
      } else {
        return <></>
      }
    } else {
      return (
      <MenuButton
        onClick={() => handleClick('edit', id)}
        icon={<EditIcon/>}
        text={'Update Assay Request'}
        tooltipPlacement={'bottom'}
      />
      )
    }
  } else if (col.type === 'hover_info') {
    const value = getValueByPath(item, col.value, '');
    return (
    <HoverInfoText text={value}/>
    )
  } else if (col.type === 'title_customer') {
    return getTitleCustomerInfo(item);
  } else if (col.type === 'hover_text') {
    const value = getValueByPath(item, col.value, '');
    return (
    <HoverTextTruncate text={value}/>
    )
  } else if (col.type === 'vendor') {
    const value = getValueByPath(item, 'assigned_to', '');
    if (Object.hasOwn(VENDOR_MASK, value)) return VENDOR_MASK[value];
    return value;
  } else if (col.type === 'view') {
    return(
      <MenuButton
        onClick={() => handleClick('view', id)}
        icon={<ViewIcon />}
        text={'View Full Assay Request'}
        tooltipPlacement={'bottom'}
      />
    )
  } else if (col.type === 'product_quantity') {
    return getProductQuantityInfo(item);
  } else if (col.type === 'quantity') {
    return getOrderLineQuantity(item);
  } else {
    const value = getValueByPath(item, col.value, '');
    if (['date', 'datetime', 'time'].includes(col.type)) {
      return getShowDate(value, col.type);
    } else if (col.type === 'number') {
      return value.toString();
    } else {
      return getStringHover(value, col.width);
    }
  }
}

export const getRequestsTableRows = (
  action: string,
  viewMode: string,
  items: RequestT[],
  cols: BasicTableFieldsT[],
  handleEditClick: (t:string, u:number)=>void
) => {
  let rows = [];
  for (const item of items) {
    let row = {};
    for (const col of cols) {
      row[col.value] = getDisplayValue(action, viewMode, col, item, handleEditClick);
    }
    rows.push(row);
  }
  return rows;
};