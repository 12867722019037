import React from 'react';
import { Button, Menu } from '@mui/material';
import MenuTooltip from '@components/elements/buttons/MenuTooltip';
import { isUserAnAdmin } from '@as_core/account/utils/admin';
import { useNavigate } from 'react-router-dom';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutIcon from '@mui/icons-material/Login';
import LoginIcon from '@mui/icons-material/Login';
import SettingsIcon from '@mui/icons-material/Settings';
import UserAvatar from '@as_core/account/components/UserAvatar';
import PullDownMenuItem from '@as_core/menus/PullDownMenuItem';
import {UserT} from '@as_core/contexts/UserContext';
import useCognito from '@as_core/account/useCognito';

interface PropsT {
  user: UserT;
}

const debug = false;

const UserProfileMenu = (props:PropsT) => {
  const { user } = props;
  if (debug) console.log('UserProfileMenu | user: ', user);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { logout } = useCognito();
  const isAdmin = isUserAnAdmin(user);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = () => {
    setAnchorEl(null);
    const loc = '/account/user';
    navigate(loc);
  };

  const handleLogout = () => {
    logout(user?.authEmail);
  };

  const handleAdmin = () => {
    setAnchorEl(null);
    const loc = '/admin/partners';
    navigate(loc);
  };

  const getMenus = (user:UserT) => {
    let pullDownMenus = [];
    if (!user.isAppAuthorized) {
      if (user.isAuthenticated) {
        pullDownMenus.push(
          <PullDownMenuItem
            key={'PD_logout'}
            icon={<LogoutIcon />}
            label={'Sign-Out'}
            onClick={handleLogout}
          />
        );
      } else {
        pullDownMenus.push(
          <PullDownMenuItem
              key={'PD_login'}
              icon={<LoginIcon />}
              label={'Sign-In'}
              onClick={() => navigate('/user/login')}
          />
        );
      }
      return pullDownMenus;
    }
    // user Account Menu Item
    pullDownMenus.push(
      <PullDownMenuItem
        key={'PD_account'}
        icon={<ManageAccountsOutlinedIcon />}
        label={'Account'}
        onClick={handleAccount} />);
    if (isAdmin) {
      pullDownMenus.push(
        <PullDownMenuItem
          key={'PD_admin'}
          icon={<SettingsIcon/>}
          label={'Admin'}
          onClick={handleAdmin}/>);
      }
    pullDownMenus.push(
      <PullDownMenuItem
          key='PD_logout'
          icon={<LogoutIcon />}
          label={'Sign-Out'}
          onClick={handleLogout}
      />
    );
    return pullDownMenus;
  }

  return (
    <>
      <MenuTooltip text={'Account'} tooltipPlacement={'bottom'}>
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <UserAvatar user={user} />
        </Button>
      </MenuTooltip>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}
      >
        { getMenus(user) }
      </Menu>
    </>
  );
};

export default UserProfileMenu;
