import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {useParams} from "react-router-dom";
import { UserContext } from '@as_core/contexts/UserContext';
import PageContainer from '@as_core/pages/PageContainer';
import { isUserAnAdmin } from '@as_core/account/utils/admin';

import {useQuery} from "@tanstack/react-query";
import DataLoading from "@as_core/elements/DataLoading";
import usePartners from "@subApps/requests/hooks/usePartners";
import { getComponent, ErrorContainer} from "./adminMenus";

const debug = false;
const PgAdmin = () => {
  const params = useParams();
  const { tab } = params;
  const { user, setUser } = useContext(UserContext);
  const { getAllPartners } = usePartners();
  const [codeToPartners, setCodeToPartners] = useState<{[key: string]: string}>({});

  if (user.subApp !== 'Admin') {
    setUser((prev) => ({...prev, subApp: 'Admin'}));
  }

  // load requests for the specific defined action
  const { isPending, isError, error, data: partners } = useQuery({
    queryKey: ['allPartners'],
    queryFn: () => getAllPartners()
  });
  if (isError) console.error('getAllPartners API error', error.message);

  // generate mapping of vendor code to vendor name
  useEffect(() => {
    if (partners !== undefined) {
      const mapping = {}
      partners.forEach((p) => {mapping[p.code] = p.name});
      setCodeToPartners(mapping);
    }
  }, partners)

  if (debug) console.log('PgAdmin | user:', user, partners, codeToPartners);

  return (
    <PageContainer title='Admin'>
      <AdminPanel>
        { isPending ?
          <DataLoading />
        :
          isUserAnAdmin(user) ?
            getComponent(tab, partners, codeToPartners)
          :
            <ErrorContainer>
              It appears that you landed on a page you are not authorized to
              access.
            </ErrorContainer>
        }
      </AdminPanel>
    </PageContainer>
  );
};

export default PgAdmin;

const AdminPanel = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  width: calc(100vw - 67px);
  overflow-y: auto;
  overflow-x: hidden;
`;

