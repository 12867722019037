import React, {useState, useContext, useEffect} from 'react';
import styled from 'styled-components/macro';
import {useQuery} from "@tanstack/react-query";
import { UserContext } from '@as_core/contexts/UserContext';
import {useNavigate, useParams} from 'react-router-dom';
import DataLoading from "@as_core/elements/DataLoading";
import PageContainer from '@as_core/pages/PageContainer';
import usePartners from "./hooks/usePartners";
import { getTitle } from './common/utils';
import Requests from "./Requests";
import {isRequestsAuthorized, NotPartnersCognitoMessage, NoAuthorizationRecordsMessage} from "./requestsMenus";
import PartnerSelector from "@subApps/requests/common/PartnerSelector";
import RequestsUnassigned from "@subApps/requests/RequestsUnassigned";

const debug = false;
const PgRequestsPartners = () => {
  const { view, id } = useParams();
  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();

  const [userAuthText, setUserAuthText] = useState<string>("");
  const [partnerCode, setPartnerCode] = useState<string | null>(null);
  const { getPartnerAuthorizationByEmail, getAllPartners } = usePartners();
  if (debug) console.log('PgRequestPartners | params: ', view, id);

  // make sure correct subApp selected for left side menus
  if (user.subApp !== 'Requests') setUser((prev) => ({...prev, subApp: 'Requests'}));

  // load authorizations for user for the specific defined action
  const { isPending: isAuthPending, isError: isAuthError, error: authError, data: partnerAuthorization } = useQuery({
    queryKey: ['partnerAuthorization', user.authId],
    queryFn: () => getPartnerAuthorizationByEmail(user)
  });
  if (isAuthError) console.error(authError.message);

  // load authorizations for user for the specific defined action
  const { isPending: isPartnersPending, isError: isPartnersError, error: partnersError, data: partners } = useQuery({
    queryKey: ['partners', 'all'],
    queryFn: () => getAllPartners()
  });
  if (isPartnersError) console.error(partnersError.message);

  const handleVendorChange = (code: string) => {
    if (debug) console.log('handleVendorChange', code, view);
    setPartnerCode(code);
    navigate(`/requests/${view}`)
  }

  useEffect(() => {
    if (partnerAuthorization && partners) {
      let authorizations = []
      partnerAuthorization.authorizations.forEach((code) => {
        let name = code;
        const partner = partners.find((p) => p.code === code);
        if (partner) name = partner.name;
        authorizations.push(name);
      });
      setUserAuthText('Authorizations: ' + authorizations.join(', '));
    }
  }, [partnerAuthorization, partners]);

  debug && console.log('PgRequestsPartners | view:',view, 'isAuthPending', isAuthPending, 'isPartnersPending', isPartnersPending,
   'partnerAuthorizations:', partnerAuthorization, 'partners:', partners);

  if (!isAuthPending && partnerAuthorization && partnerCode === null) {
    setPartnerCode(partnerAuthorization.authorizations[0]);
  }

  if (!isRequestsAuthorized(user)) {
    return (
      <PageContainer title={'Requests Handling - Not Authorized'}>
        <NotPartnersCognitoMessage />
      </PageContainer>
    )
  } else if (!isAuthPending && partnerAuthorization === undefined) {
    return(
      <PageContainer title={'Requests Handling - Not Authorized'}>
        <NoAuthorizationRecordsMessage />
      </PageContainer>
    )
  }

  return (
    <PageContainer title={getTitle(view)} midBanner={userAuthText}>
      <Container heightShift={partnerAuthorization && partnerAuthorization.authorizations.length > 1 ? 50 : 0}>
        { isAuthPending || isPartnersPending ?
          <DataLoading />
        :
          <>
            { view === 'unassigned' ?
              <RequestsUnassigned />
            :
              <>
                { partnerAuthorization && partnerAuthorization.authorizations.length > 1 ?
                  <PartnerSelector
                    key={partnerCode}
                    view={view}
                    authorizations={partnerAuthorization.authorizations}
                    partners={partners}
                    partnerCode={partnerCode}
                    handleClick={handleVendorChange}
                  />
                  : null
                }
                <Requests
                  view={view}
                  partnerCode={partnerCode}
                />
              </>
            }
          </>
        }
      </Container>
    </PageContainer>
  );
};

export default PgRequestsPartners;

const Container = styled.div<{heightShift: number}>`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - ${(p) => p.heightShift}px - 20px);
  overflow-y: hidden;
  overflow-x: hidden;
  & ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
  }
  & ::-webkit-scrollbar-track {
      background: transparent;
  }
  & ::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.palette.accentPrimary};
      border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
      background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

