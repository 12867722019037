import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import ErrorMessages, {MessageItem} from '@as_core/elements/UserMessages';
import BasicTable from '@as_core/tables/BasicTable';
import { ColDef } from '../utils/tableDefinitions';
import { getRequestsTableRows } from '../utils/renderValues';
import {UserEmailDictionaryT} from '../hooks/useUserEmails';
import useRequests, { RequestT } from '../hooks/useRequests';

interface PropsI {
  action: string;
  requests: RequestT[];
  handleClick: (t: string, id: number) => void;
  userEmails: UserEmailDictionaryT;
}

const debug = false;
const RequestsTable = (props: PropsI) => {
  const { action, requests, handleClick, userEmails } = props;
  const { getRequestType } = useRequests();

  const [requestsRows, setRequestsRows] = useState<RequestT[]>([]);
  debug && console.log('partners/RequestsTable | {requests}:', requests);

  const cols = Object.hasOwn(ColDef, action) ? ColDef[action] : ColDef['open'];
  debug && console.log('RequestsTable: {cols}', cols);

  useEffect(() => {
    if (requests !== undefined) {
      const updatedRequests = requests.map((r) => ({
        ...r, itemType: getRequestType(r).category, userEmail: Object.hasOwn(userEmails, r.user_id) ? userEmails[r.user_id] : r.user_id
      }))
      setRequestsRows(getRequestsTableRows(action, 'partners', updatedRequests, cols, handleClick));
    }
  }, [requests]);

  debug && console.log('RequestsTable: {requestsRows}', requestsRows);

  return (
    <>
      { requests.length ?
        <RequestTableContainer>
          <BasicTable
            fields={cols}
            rows={requestsRows}
            border={false}
          />
        </RequestTableContainer>
        :
        <ErrorMessages messages={[
          <MessageItem key='emi_no_requests'>You do not have any {action} requests</MessageItem>
        ]}/>
      }
    </>
  );
};

export default RequestsTable;

const RequestTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

