import React, { useContext } from 'react';
import PageContainer from '@as_core/pages/PageContainer';
import PagePanel from '@as_core/pages/PagePanel';
import HomePageTiles from '@as_core/tiles/HomePageTiles';
import { asedaCards, toolsCards } from '@app_config/homeCards';
import { UserContext } from '@as_core/contexts/UserContext';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgHome = () => {
  const { user, setUser } = useContext(UserContext);
  debug && console.log('PgHome | userInfo:', user);

  if (user.subApp !== '') {
    setUser((prev) => ({
      ...prev,
      subApp: '',
    }));
  }

  return (
    <PageContainer title='Welcome'>
      <PagePanel title={'3RnD Partners Tools'}>
        <HomePageTiles key='tool_cards' tiles={toolsCards} />
      </PagePanel>
      <PagePanel title={'AsedaSciences Active Applications'}>
        <HomePageTiles key='aseda_sciences' tiles={asedaCards} />
      </PagePanel>
    </PageContainer>
  );
};

export default PgHome;
