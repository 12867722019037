import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';

export const ColDef: {[key:string]: BasicTableFieldsT[]} = {
  'open': [
    { value: 'view', label: '', type: 'view', width: 40 },
    { value: 'edit', label: '', type: 'edit', width: 40 },
    { value: 'status', label: 'Status', type: 'status', width: 150 },
    { value: 'title_customer', label: 'Request Name/Customer', type: 'title_customer', width: 300 },
    { value: 'product_quantity', label: 'Product/Quantity', type: 'product_quantity', width: 300 },
    { value: 'created_at', label: 'Created', type: 'date', width: 70 },
    { value: 'updated_at', label: 'Updated', type: 'date', width: 70 },
  ],
  'closed': [
    { value: 'view', label: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', type: 'computed', width: 55 },
    { value: 'title', label: 'Title', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', type: 'string', width: 75 },
    { value: 'resolution.assigned', label: 'Assigned To', type: 'string', width: 240 },
    { value: 'submitter', label: 'Submitter', type: 'string', width: 200 },
    { value: 'submit.submitter.email', label: 'Sub\'d', type: 'date', width: 80 },
    { value: 'resolution.time_resolved', label: 'Resolved', type: 'date', width: 80 },
  ]
};

export const RequestDetailsFields: {[viewMode: string]: BasicTableFieldsT[]} = {
  'user': [
    { value: 'status',      label: 'Request Status', type: 'status'},
    { value: 'prod_name',   label: 'Product',        type: 'string'},
    { value: 'assigned_to', label: 'Assigned To',    type: 'vendor'},
    { value: 'cat_code',    label: 'Catalog #',      type: 'string'},
    { value: 'quantity',    label: 'Quantity',       type: 'quantity'},
    { value: 'created_at',  label: 'Created',        type: 'date'},
    { value: 'updated_at',  label: 'Last Updated',   type: 'datetime'}
  ],
  'partners': [
    { value: 'status',      label: 'Request Status', type: 'status'},
    { value: 'user_id',     label: 'Customer',       type: 'email' },
    { value: 'prod_name',   label: 'Type',           type: 'string'},
    { value: 'cat_code',    label: 'Catalog #',      type: 'string'},
    { value: 'quantity',    label: 'Quantity',       type: 'quantity'},
    { value: 'created_at',  label: 'Created',        type: 'datetime'},
    { value: 'updated_at',  label: 'Last Updated',   type: 'datetime'}
  ]
};