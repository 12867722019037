import styled from "styled-components/macro";
import {AddCircle, RemoveCircle, PushPinOutlined} from "@mui/icons-material";

export const PushPin = styled(PushPinOutlined)`
  display: flex;
  color: ${(p) => p.theme.palette.accentPrimary};
  cursor: pointer;
  transform: rotate(45deg);
  filter: grayscale(1.0);
  :hover {
    filter: grayscale(0.0);
  }
  &.active {
    transform: rotate(0deg);
    filter: grayscale(0.0);
  };
`;

export const AddItem = styled(AddCircle)`
  display: flex;
  color: ${(p) => p.theme.palette.accentPrimary};
  cursor: pointer;
  filter: grayscale(1.0);
  :hover {
    filter: grayscale(0.0);
  }
  &.active {
    transform: rotate(0deg);
    filter: grayscale(0.0);
  };
`;

export const RemoveItem = styled(RemoveCircle)`
  display: flex;
  color: ${(p) => p.theme.palette.accentPrimary};
  cursor: pointer;
  filter: grayscale(1.0);
  :hover {
    filter: grayscale(0.0);
  }
  &.active {
    transform: rotate(0deg);
    filter: grayscale(0.0);
  };
`;