import React, { useState } from 'react';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import ErrorMessages, {MessageItem} from '@as_core/elements/UserMessages';
import { RequestFormsContainer, RequestFormsHeader, Column, Row, HorizontalLine } from '../common/flexStyles';
import {RequestT, RequestUpdateT} from '../hooks/useRequests';
import RequestToDo, {StatusMapT} from '../common/RequestToDo';
import RequestFormFiles from '../files/RequestFormFiles';
import ShippingInfoDetails from './ShippingInfoDetails';
import useRequestStatus, { RequestStatusT } from '../hooks/useRequestStatus';
import PlateMapTemplate from './PlateMapTemplate';

const UserRequestInfo:string = 'For this request to be fulfilled by AsedaSciences and its partners '
 + 'you must perform the actions in the Steps To Complete list.'

interface PropsI {
  viewMode: string;
  request: RequestT;
  status: RequestStatusT;
  allowEdit?: boolean;
  updateSelectedRequest: (id: number, updates: RequestUpdateT) => Promise<void>;
}

const debug = false;
const AssayRequestForms = (props: PropsI) => {
  const {viewMode, request, status, allowEdit=false, updateSelectedRequest} = props;
  const [isTrackingAvailable, setIsTrackingAvailable] = useState<boolean>(false);
  const [numberPlateMaps, setNumberPlateMaps] = useState<number>(0);
  const { isStageCompleted } = useRequestStatus();
  if (debug) console.log('AssayRequestForms | {viewMode, request.id, allowEdit, status}:',
      viewMode, request.id, allowEdit, status);

  function getHeader() {
    return viewMode === 'partners' ? 'User Supplied Information' : request.status === 'Open' ?
      'User Actions Required' : 'User Actions Completed';
  }

  if (debug) console.log('AssayRequestForms | request.id:', request.id,
    'numberPlateMaps:', numberPlateMaps, 'isTrackingAvailable:', isTrackingAvailable);

  const statusMap: StatusMapT[] = [
    {label: 'Upload Plate Map(s)', checked: isStageCompleted(status,'plateMapAvailable')},
    {label: 'Confirm Shipping', checked: isStageCompleted(status, 'compoundsShipped')},
  ]

  return (
    <RequestFormsContainer>
      <RequestFormsHeader>{getHeader()} {viewMode === 'user' && request.status === 'Open' ?
          <HoverInfoText text={UserRequestInfo}/> : null }</RequestFormsHeader>
      { viewMode === 'user' ?
        <Column>
          { request.assigned_to && request.assigned_to !== '' ?
          <>
            <Row padding={'0'}>
              <RequestToDo viewMode={viewMode} statusMap={statusMap}/>
            </Row>
            { !isStageCompleted(status, 'compoundsShipped') ?
              <Row>
                <PlateMapTemplate request={request} />
              </Row>
            : null }
            <RequestFormFiles
              viewMode={viewMode}
              formType='plate_map'
              formName='Plate Map'
              request={request}
              setNumberForms={setNumberPlateMaps}
              allowEdit={viewMode === 'user' && !isStageCompleted(status, 'compoundsShipped')}
            />
            <ShippingInfoDetails
              viewMode={viewMode}
              active={statusMap[0].checked && allowEdit}
              request={request}
              updateSelectedRequest={updateSelectedRequest}
              setIsTrackingAvailable={setIsTrackingAvailable}
            />
          </>
          :
          <Row>
            <ErrorMessages border={false} messages={[
              <MessageItem key='emi_no_action'>No action needed at this time.</MessageItem>,
              <MessageItem key='emi_processing'>Order still being processed to assign to laboratory.</MessageItem>
            ]}/>
          </Row>
          }
        </Column>
      :
        <Column width={'100%'} h_align={'center'}>
          <RequestFormFiles
            viewMode={viewMode}
            formType='plate_map'
            formName='Plate Map'
            request={request}
            setNumberForms={setNumberPlateMaps}
          />
          { isTrackingAvailable !== null && isTrackingAvailable ? <HorizontalLine /> : null }
          <ShippingInfoDetails
            viewMode={viewMode}
            active={statusMap[0].checked && allowEdit}
            request={request}
            updateSelectedRequest={updateSelectedRequest}
            setIsTrackingAvailable={setIsTrackingAvailable}
          />
        </Column>
      }
    </RequestFormsContainer>
  );
};

export default AssayRequestForms;
