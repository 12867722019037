import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@as_core/contexts/UserContext';
import {useQuery} from "@tanstack/react-query";
import usePartners, {PartnerAuthorizationT} from "@subApps/requests/hooks/usePartners";
import DataLoading from "@as_core/elements/DataLoading";
import BasicTable from "@as_core/tables/BasicTable";
import MenuButton from "@as_core/elements/MenuButton";
import {AddItem} from "@as_core/icons/styledIcons";
import DraggableModal from "@as_core/elements/DraggableModal";
import {dataToTableRows} from "./utils/renderValues";
import { AuthorizationFields as cols } from "./utils/tableDefinitions";
import UpdateAuthorizations from "./forms/UpdateAuthorizations";

interface PropsI {
  codeToName: {[key: string]: string};
}

type UpdateTypesT = 'create' | 'update' | 'delete';

const modalSizes: {[key: string]: {height: number, width: number}} = {
  'create': {height: 275, width: 500},
  'update': {height: 215, width: 500},
  'delete': {height: 175, width: 500}
}

const debug = false;
const Authorizations = (props:PropsI) => {
  const { codeToName } = props;
  const { user } = useContext(UserContext);
  const { getAllAuthorizations } = usePartners();
  const [rows, setRows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [updateType, setUpdateType] = useState<UpdateTypesT>('update');
  const [editAuthorization, setEditAuthorization] = useState<PartnerAuthorizationT>(null);

  if (debug) console.log('Authorizations | user:', user, ' codeToName: ', codeToName);

  // HANDLERS
  const handleClick = (type: UpdateTypesT, itemId:string) => {
    if (debug) console.log('Authorizations | handleClick {type, itemId', type, itemId);
    setIsModalOpen(true);
    setUpdateType(type);
    if (type !== 'create') {
      setEditAuthorization(partnerAuthorizations.find((p) => p.id === itemId))
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditAuthorization(null);
  }

  // load requests for the specific defined action
  const { isPending, error, isError, data: partnerAuthorizations } = useQuery({
    queryKey: ['authorizations', 'all'],
    queryFn: () => getAllAuthorizations()
  });
  if (isError) console.error('Authorizations.getAllAuthorizations | error:', error.message);

  useEffect(() => {
    if (partnerAuthorizations !== undefined) {
      setRows(dataToTableRows(partnerAuthorizations, cols, codeToName, handleClick));
    }
  }, [partnerAuthorizations]);

  if (debug) console.log('Authorizations | partnersAuthorizations:', partnerAuthorizations, 'rows:', rows);

  return (
    <Container>
      { isPending ? <DataLoading />
      :
        <>
          <MenuButton
            text={''}
            icon={<AddItem />}
            onClick={() => handleClick('create', '')}
          />
          <BasicTable
            fields={cols}
            rows={rows}
            border={false}
          />
        </>
      }
      <DraggableModal
        title={updateType === 'create' ? 'Add a New Authorization'
          : updateType === 'delete' ? 'Delete An Authorization'
          : 'Edit a Person\'s Authorization'}
        height={Object.hasOwn(modalSizes, updateType) ? modalSizes[updateType].height : 400}
        width={Object.hasOwn(modalSizes, updateType) ? modalSizes[updateType].width : 500}
        isModalOpen={isModalOpen}
        onCloseClick={handleCancel}
      >
        <UpdateAuthorizations
          updateType={updateType}
          authorization={editAuthorization}
          codeToName={codeToName}
          closeDialog={handleCancel}
        />
      </DraggableModal>
    </Container>
  );
};

export default Authorizations;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
