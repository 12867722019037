import {AxiosRequestConfig, AxiosResponse} from "axios";
import useCognito from "@as_core/account/useCognito";
import {authServicesClient} from "@utils/api/base";
import {processAxiosResponse} from '@utils/api/utils';

// the Request Object Type
export type RequestT = {
  id: number;
  name: string;
  user_id: string;
  org_id: string;
  order_id: string;
  created_at: string;
  updated_at: string;
  request_category: string;
  request_type: string;
  cat_code: string;
  prod_name: string;
  quantity: number;
  unit_value: string;
  assigned_to: string;
  status: string;
  compounds: number;
  itemType?: string;
  userEmail?: string;
}

// the Request Update Object Type
export type RequestUpdateT = {
  name?: string;
  user_id?: string;
  org_id?: string;
  request_type?: string;
  cat_code?: string;
  prod_name?: string;
  quantity?: number;
  unit_value?: string;
  assigned_to?: string;
  status?: string;
}

const OrderedStatus: {[key: string]: string[]} = {
  'Screen': ['Open', 'Platemap Available', 'Shipped', 'Received', 'Running', 'Loading', 'QC Check', 'Delivered', 'Complete'],
  'Consulting': ['Open', 'Platemap Available', 'Shipped', 'Received'],
}


const ActionToStatus: {[key: string]: string} = {
  'open': 'Open',
  'platemap_available': 'Platemap Available',
  'shipped': 'Shipped',
  'received': 'Received',
  'running': 'Running',
  'loading': 'Loading',
  'qc': 'QC Check',
  'delivered': 'Delivered',
  'complete': 'Complete',
}

// the api settings
export const Requests = {
  all: (token: string, config: AxiosRequestConfig) => authServicesClient(token).get('/requests', config),
  get: (token: string, orderId: string) => authServicesClient(token).get(`/requests/${orderId}`),
  byId: (token: string, query: string) => authServicesClient(token).get(`/requests/list${query}`),
  byStatus: (token: string, status: string) => authServicesClient(token).get(`/requests?status=${status}`),
  byOrder: (token: string, orderId: string) => authServicesClient(token).get(`/requests/order_id/${orderId}`),
  forUser: (token:string, userId: string) => authServicesClient(token).get(`/requests/user/${userId}`),
  forPartner: (token:string, partnerCode: string) => authServicesClient(token).get(`/requests/partner_id/${partnerCode}`),
  create: (token: string, config: AxiosRequestConfig) => authServicesClient(token).post('/requests', config),
  update: (token: string, requestId: string, config: AxiosRequestConfig) => authServicesClient(token).patch(`/requests/${requestId}`, config),
};


export function actionToStatus(action:string) {
  if (Object.hasOwn(ActionToStatus, action)) return ActionToStatus[action];
  console.error('Unknown API status for action: ', action);
  return action;
}

const useRequests = () => {
  const { getToken } = useCognito();

  const getSortedRequests = (tag: string, response: AxiosResponse<RequestT[]>): RequestT[] => {
    const forms: RequestT[] = processAxiosResponse(tag, response);
    forms.sort((a, b) => b.id - a.id);
    return forms;
  }

  const getRequests = async (params: { [key: string]: string }): Promise<RequestT[]> => {
    const config: AxiosRequestConfig = { params };
    let resp: AxiosResponse<RequestT[]> = await Requests.all(getToken(), config);
    return getSortedRequests('getRequest', resp);
  };

  const getRequestsByIds = async (ids: number[]): Promise<RequestT[]> => {
    if (ids.length === 0) return [];
    const id_query = ids.map((id, index) => ((index === 0? '': '&') + `ids=${id}`));
    const query = '?'.concat(...id_query);
    let resp: AxiosResponse<RequestT[]> = await Requests.byId(getToken(), query);
    return getSortedRequests('getRequestsByIds', resp);
  };

  const getRequestsByStatus = async (status:string): Promise<RequestT[]> => {
    const token = getToken();
    let resp: AxiosResponse<RequestT[]> = await Requests.byStatus(token, actionToStatus(status));
    return getSortedRequests('getRequestsByStatus', resp);
  };

  const getUnassignedRequests = async (): Promise<RequestT[]> => {
    const token = getToken();
    let resp: AxiosResponse<RequestT[]> = await Requests.byStatus(token, actionToStatus('open'));
    const openRequests = getSortedRequests('getRequestsByStatus', resp);
    return openRequests ? openRequests.filter((r) => r.assigned_to === '') : [];
  };

  const getRequestsByOrder = async (orderId:string): Promise<RequestT[]> => {
    const token = getToken();
    let resp: AxiosResponse<RequestT[]> = await Requests.byOrder(token, orderId);
    return getSortedRequests('getRequestsByOrder', resp);
  };

  const getPartnerRequests = async (partnerCode:string): Promise<RequestT[]> => {
    const token = getToken();
    let resp: AxiosResponse<RequestT[]> = await Requests.forPartner(token, partnerCode);
    return getSortedRequests('getRequestsByOrder', resp);
  };

  const getUserRequests = async (userId: string): Promise<Array<RequestT>> => {
    let resp: AxiosResponse<RequestT[]> = await Requests.forUser(getToken(), userId);
    return getSortedRequests('getUserRequests', resp);
  };

  const createRequest = async (params: { [key:string]: string | number }): Promise<RequestT> => {
    const config: AxiosRequestConfig = params;
    let resp: AxiosResponse<RequestT> = await Requests.create(getToken(), config);
    return processAxiosResponse('createRequests', resp);
  };

  const updateRequest = async (requestId: number, updates: {[key: string]: string | number}): Promise<RequestT> => {
    const config: AxiosRequestConfig = updates;
    let resp: AxiosResponse<RequestT> = await Requests.update(getToken(), requestId.toString(), config);
    return processAxiosResponse('updateRequest', resp);
  };

  const getRequestType = (request: RequestT): { category: string, type: string } => {
    return {category: request.request_category, type:request.request_type};
  }

  const filterRequestsByAction = (action: string, requests: RequestT[]): RequestT[] => {
    if (!requests || requests.length === 0) return requests;
    return requests.filter((request) => request.status === ActionToStatus[action]);
  }

  const filterRequestsByStatus = (status: string, requests: RequestT[]): RequestT[] => {
    if (!requests || requests.length === 0) return requests;
    return requests.filter((request) => request.status === status);
  }

  const getRequestStages = (requestType: string): string[] => {
    if (!Object.hasOwn(OrderedStatus, requestType)) return [];
    return OrderedStatus[requestType];
  }

  return {
    getRequests,
    getUnassignedRequests,
    getRequestsByIds,
    getRequestsByStatus,
    getRequestsByOrder,
    getRequestType,
    getUserRequests,
    getPartnerRequests,
    createRequest,
    updateRequest,
    getRequestStages,
    filterRequestsByAction,
    filterRequestsByStatus
  };
};

export default useRequests;
