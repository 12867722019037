import React from "react";
import {Tooltip} from "@mui/material";
import {truncateString} from "@utils/misc";

type PropsT = {
  text: string
  characters?: number;
}
const HoverTextTruncate = (props:PropsT) => {
  const {text, characters=20} = props;
  const halfCharacters = Math.floor(characters/2);
  return(
    <>
      { text.length < characters ?
        <>text</>
      :
        <Tooltip title={text}>
          <>
            {truncateString(text, characters, halfCharacters)}
          </>
        </Tooltip>
      }
    </>
  )
}

export default HoverTextTruncate;
