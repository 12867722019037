import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {Label, Row, Value, Item} from "@subApps/requests/common/flexStyles";
import TextButton from "@as_core/controls/buttons/TextButton";
import InputField from "@as_core/components/inputs/InputField";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import usePartners, {PartnerT} from "@subApps/requests/hooks/usePartners";

type PropsT = {
  updateType: string;
  partner?: PartnerT;
  closeDialog?: () => void;
}

function getInitialUpdate(partner: PartnerT): {[key: string]: string | number} {
  if (partner === null) return {};
  let initial = {};
  ['code', 'name', 'keyContact'].forEach(key => {
    if (Object.hasOwn(partner, key)) initial[key] = partner[key];
  })
  return initial;
}

const isDisabled = (
  updateType: string,
  partner: PartnerT,
  updates: {[key: string]: string | number}
) => {
  if (updateType === 'delete') return false;
  if (updateType === 'create') {
    ['code', 'name'].forEach((p) => {
      if (!Object.hasOwn(updates, p) || updates[p] === '') return true;
    })
    return false;
  }
  if (updateType === 'update') {
    console.log('updates', updates, 'partner', partner);
    let disable = true;
    Object.keys(updates).forEach(key => {
      if (updates[key] !== partner[key]) disable = false;
    })
    return disable;
  }
  return true;
}

const debug = false;
const UpdatePartners = (props:PropsT) => {
  const {updateType, partner=null} = props;
  const queryClient = useQueryClient();
  const {createPartner, updatePartner, deletePartner} = usePartners();
  const [updates, setUpdates] = useState<{ [key: string]: string | number }>(getInitialUpdate(partner));
  debug && console.log('UpdatePartners | updateType: ', updateType, ' partner:', partner);

  const handleAPICall = async (
    id: string,
    updates: {[key: string]: string | number}
  ) => {
    switch (updateType) {
      case 'create':
        return createPartner(updates)
      case 'update':
        return updatePartner(id, updates);
      case 'delete':
        return deletePartner(id);
    }
    console.error('UpdatePartners | unknown update Type: ', updateType);
  }

  const mutation = useMutation({
    mutationFn: (param: {id: string, updates: { [key: string]: string | number }}) => handleAPICall(param.id, param.updates),
    onSuccess: (data) => {
      if (debug) console.log('UpdatePartners | mutation | OnSuccess | data:', data);
      props?.closeDialog && props.closeDialog();
      queryClient.invalidateQueries({ queryKey: ['allPartners'] }).then();
    }
  });

  const handleSubmit = async () => {
    const id = partner === null ? null : partner.id;
    if (debug) console.log('UpdatePartners | handleSubmit {id, updates}:', id, updates);
    mutation.mutate({id: id, updates: updates});
  }

  const _handleFieldUpdate = (field:string, value: string | number) => {
    if (debug) console.log('_handleFieldUpdate', field, value);
    setUpdates((prev) => ({...prev, [field]: value }));
  }

  const handleCancel = () => {
    if (debug) console.log('UpdatePartners.handleCancel | Cancel Clicked');
    props?.closeDialog && props.closeDialog();
  }

  const disabled = isDisabled(updateType, partner, updates)

  return (
    <UpdatePartnersContainer>
      { partner === null ?
        <Row h_align={'flex-start'} v_align={'center'}>
          <Label width={'120px'}>Partner Code:</Label>
          <Item height='max-content' width='273px'>
            <InputField
              key='code'
              label=''
              placeholder={'... enter partner code'}
              value={Object.hasOwn(updates, 'code') ? updates['code'] : ''}
              onChange={(e) => _handleFieldUpdate('code', e.target.value)}
            />
          </Item>
        </Row>
        :
        <Row h_align={'flex-start'} v_align={'center'} height={'42px'}>
          <Label width={'120px'}>Partner Code:</Label>
          <Value>{partner.code}</Value>
        </Row>
      }
      { updateType === 'delete' ?
        <Row h_align={'center'} v_align={'center'} width='100%' height = '60px'>
          <Label width={'max-content'}>Confirm Deleting Partner: {partner.name}</Label>
        </Row>
        :
        <>
          <Row h_align={'flex-start'} v_align={'center'}>
            <Label width={'120px'}>Partner Name:</Label>
            <Item height='max-content' width='273px'>
              <InputField
                key='name'
                label=''
                placeholder={'... enter partner name'}
                value={Object.hasOwn(updates, 'name') ? updates['name'] : ''}
                onChange={(e) => _handleFieldUpdate('name', e.target.value)}
              />
            </Item>
          </Row>
          <Row h_align={'flex-start'} v_align={'center'}>
            <Label width={'120px'}>Key Contact:</Label>
            <Item height='max-content' width='273px'>
              <InputField
                key='keyContact'
                label=''
                value={Object.hasOwn(updates, 'keyContact') ? updates['keyContact'] : ''}
                onChange={(e) => _handleFieldUpdate('keyContact', e.target.value)}
              />
            </Item>
          </Row>
        </>
      }
      <Row>
        <TextButton width={100} label={'Cancel'} onClick={() => handleCancel()} />
        <TextButton
          width={100}
          label={partner === null ? 'Create' : updateType === 'delete' ? 'Delete' :'Save Changes'}
          isDisabled={disabled}
          onClick={disabled ? ()=>{} : () => handleSubmit()}
        />
      </Row>
    </UpdatePartnersContainer>
  );
};

export default UpdatePartners;

const UpdatePartnersContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
