import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}


const CellhealthIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={color} d="M22.6,10.2c-0.7-4.5-4.3-8.1-8.8-8.8c-3.2-0.5-6.2,0.4-8.5,2.2l0,0C5.2,3.8,5.1,4,5.1,4.2C5.1,4.6,5.4,5,5.8,5
	C6,5,6.2,4.9,6.4,4.7c1.9-1.5,4.5-2.3,7.2-1.8c3.8,0.6,6.9,3.7,7.5,7.5c1,6.3-4.3,11.7-10.7,10.7c-3.9-0.6-7-3.7-7.6-7.6
	c-0.4-2.2,0.1-4.2,1.1-6C4,7.4,4,7.3,4,7.2c0-0.4-0.3-0.8-0.8-0.8C3,6.4,2.8,6.6,2.7,6.8l0,0c-1.1,2-1.6,4.3-1.3,6.7
	c0.6,4.6,4.3,8.3,8.8,9.1C17.5,23.8,23.8,17.5,22.6,10.2z" />
          <path fill={colorHighlight} d="M3.5,12.2C3.6,15.8,6,19,9.4,20.1c2.5,0.8,4.9,0.4,6.9-0.8l0,0c0.1-0.1,0.2-0.2,0.3-0.4c0-0.3-0.2-0.6-0.5-0.7
	c-0.2,0-0.3,0-0.5,0.1c-1.7,1-3.8,1.3-5.8,0.6c-2.9-0.9-5-3.7-5-6.8C4.6,7.1,9.4,3.5,14.3,5c2.9,0.9,5,3.7,5.1,6.8
	c0,1.8-0.6,3.3-1.5,4.6c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.6,0.5,0.7c0.2,0,0.4-0.1,0.5-0.2l0,0c1.1-1.4,1.8-3.2,1.8-5.1
	c0-3.7-2.4-7-5.9-8.1C9,2.1,3.4,6.3,3.5,12.2z" />
          <path fill={colorHighlight} d="M15.3,14.4c-0.2,0.3-0.4,0.5-0.6,0.7l-0.4-0.6c0.7-0.6,1.1-1.5,1.1-2.5c0-1.9-1.5-3.4-3.4-3.4
	c-1.1,0-2.1,0.6-2.7,1.4L8.6,9.7C8.8,9.5,9,9.2,9.2,9.1L8.7,8.6c-0.6,0.6-1.1,1.3-1.3,2.1c0,0.1-0.1,0.3-0.1,0.4L8,11.2
	c0.1-0.3,0.2-0.6,0.3-0.9l0.6,0.4c-0.2,0.4-0.3,0.8-0.3,1.3c0,1.9,1.5,3.4,3.4,3.4c0.6,0,1.2-0.2,1.7-0.5l0.4,0.6
	c-0.3,0.2-0.6,0.3-0.9,0.4l0.2,0.6c1-0.3,1.9-0.9,2.5-1.8L15.3,14.4z M9.1,12c0-1.6,1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9
	s-1.3,2.9-2.9,2.9S9.1,13.6,9.1,12z" />
        </g>
      </svg>
    </>
  )
}

export default CellhealthIcon;