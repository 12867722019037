import React from 'react';
import CellHealthIcon from './cellhealth.icon';
import ZebrafishIcon from './zebrafish.icon';
import BiomimeticsIcon from './biomimetics.icon';
import CompoundQCIcon from './compoundQC.icon';
import styled from 'styled-components/macro';

interface IconPropsI {
  size?: number
}

const AssayOrdersIcon = (props:IconPropsI) => {
  const { size = 24 } = props;
  return (
    <Container>
      <Row>
        <CellHealthIcon size={size} />
        <ZebrafishIcon  size={size} />
      </Row>
      <Row>
        <BiomimeticsIcon size={size} />
        <CompoundQCIcon size={size}  />
      </Row>
    </Container>
  );
};

export default AssayOrdersIcon;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  height: max-content;
`

