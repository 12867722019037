import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import SignIn from '@as_core/account/forms/SignIn';
import SignUp from '@as_core/account/forms/SignUp';
import VerifyEmail from "@as_core/account/forms/VerifyEmail";
import Register from '@as_core/account/forms/Register';
import useUserRegistrationInfo from '@utils/useUserRegistrationInfo';
import LoadingMessage from '@as_core/account/LoadingMessage';

import { UserContext } from '@as_core/contexts/UserContext';
import ForgotPassword from '@as_core/account/forms/ForgotPassword';
import useCognito from "@as_core/account/useCognito";
import { getUserRegion, getUserCountryCode } from '@as_core/account/AuthWrapper';

const debug: boolean = false;
const PgAuthentication = () => {
  const { action } = useParams();
  const { user, setUser } = useContext(UserContext);
  const [redirect, setRedirect] = useState<string | null>(null);
  const [isRegDataLoading, setIsRegDataLoading] = useState(action === 'new');
  const [statusMessage, setStatusMessage] = useState('');
  const { getUserRegistrationInfo } = useUserRegistrationInfo();
  const { getToken } = useCognito();
  const navigate = useNavigate();
  const {isAuthenticated, isEmailVerified, isRegistered} = user;
  if (debug) console.log(`PgAuthentication | action: "${action}" authEmail: ${user.authEmail} isAuthenticated: ${isAuthenticated} `,
    ` isEmailVerified: ${isEmailVerified} isRegistered: ${isRegistered}`);

  // check for url to redirect as parameter - on mount
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get('url');
    if (debug) console.log('query URL ', url);
    if (url !== undefined) setRedirect(url);
  }, []);

  // handle the user registration data -- only called for sign-in as sign-up will do this
  useEffect(() => {
    if (debug) console.log('PgAuthentication: User auth/reg/loading state changed', isAuthenticated, isEmailVerified, isRegistered, isRegDataLoading);
    if (action!=='register' && isAuthenticated && isEmailVerified && !isRegistered && !isRegDataLoading) {
      setStatusMessage('Retrieving User Registration Data');
      setIsRegDataLoading(true);
      if (debug) console.log('Loading User Registration Data');
      getUserRegistrationInfo(getToken()).then((userRegInfo) => {
        setIsRegDataLoading(false);
        if (debug) console.log('PgAuthentication | getUserRegistrationInfo {userRegInfo}:', userRegInfo);
        setUser((prev) => ({
          ...prev,
          isRegistered: true,
          userRegion: getUserRegion(userRegInfo),
          userCountryCode: getUserCountryCode(userRegInfo),
          regInfo: userRegInfo
        }));
        if (redirect) {
          if (debug) console.log('Redirecting', redirect);
          navigate(redirect);
        } else {
          navigate('/home');
        }
      }).catch((reason)=>{
        if (reason !== 'user not registered') {
          console.error('PgAuthentication getUserRegistration Info | Error', reason);
          // alert('Error loading user registration -- empty or issues with API?' + reason);
        }
        setIsRegDataLoading(false);
        setStatusMessage('');
        navigate('/user/register');
      });
    }
  }, [isAuthenticated, isRegistered, isRegDataLoading]);

  return (
    <Center>
      {statusMessage ? (
        <LoadingMessage message={statusMessage}/>
      ) : action === 'new' ? (
        <Scroll>
          <SignUp key='sign-up-form' />
        </Scroll>
      ) : action === 'verify-email' ? (
        <Scroll>
          <VerifyEmail key='verify-email-form' />
        </Scroll>
      ) : action === 'register' ? (
        <Scroll>
          <Register key='register-form' />
        </Scroll>
      ) : action === 'forgot' ? (
        <ForgotPassword />
      ) : (
        <SignIn key='signin-in-component' setMessage={setStatusMessage}/>
      )}
    </Center>
  );
};

export default PgAuthentication;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Scroll = styled.div`
  display: flex;
  width: max-content;
  height: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
  ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;

