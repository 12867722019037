import React from 'react';
import styled from 'styled-components/macro';
import RequestInfoHeader from '../common/RequestInfoHeader';
import Assign from "./partner/Assign";
import StatusUpdate from "./partner/StatusUpdate";
import { RequestT, RequestUpdateT } from '../hooks/useRequests';
import UploadData from "./partner/UploadData";

export const ActionToModalTitle: {[key: string]: {[key:string]: string}} = {
  'user': {},
  'partners': {
    'unassigned' : 'Assign Request',
    'shipped'  : 'Update Request Status',
    'received': 'Update Request Status',
    'running': 'Upload Assay Data',
  }
}

export function getRequestUpdateModalTitle(request: RequestT, viewMode): string {
  if (!request) return '';
  const status = getNormalizedStatus(request);
  if (Object.hasOwn(ActionToModalTitle, viewMode)) {
    if (viewMode === 'partners') {
      if (Object.hasOwn(ActionToModalTitle[viewMode], status))
        return ActionToModalTitle[viewMode][status];
    }
  }
  return status;
}

export const UpdateModalDimensions: {[key: string]: {height: number, width: number}} = {
  'unassigned': { height: 200, width: 400 },
  'shipped': { height: 400, width: 500 },
  'received': { height: 400, width: 500 },
  'running': { height: 400, width: 450 },
};

function getNormalizedStatus(request: RequestT):string {
  if (!request) return '';
  let status = request.status.toLowerCase();
  if (status === 'open' && request.assigned_to === '') status = 'unassigned';
  return status;
}

export function getModalHeight(request: RequestT): number {
  const status = getNormalizedStatus(request);
  return Object.hasOwn(UpdateModalDimensions, status) ? UpdateModalDimensions[status].height : 600;
}

export function getModalWidth(request: RequestT): number {
  const status = getNormalizedStatus(request);
  return Object.hasOwn(UpdateModalDimensions, status) ? UpdateModalDimensions[status].width : 600;
}

function getForm(
  viewMode: string,
  request:RequestT,
  handleRequestUpdate: (id:number, updates: RequestUpdateT) => void,
  handleCancel: () => void,
) {
  let action = request.status.toLowerCase();
  if (action === 'open' && request.assigned_to === '') action = 'unassigned'
  switch(action) {
    case 'unassigned':
      if (viewMode == 'partners') {
        return (
          <Assign
          request={request}
          handleUpdate={handleRequestUpdate}
          handleCancel={handleCancel}
          />
        )
      } else {
        return (
          <>Unknown form for action:{action} and viewMode:{viewMode}</>
        )
      }
    case 'shipped':
    case 'received':
      return (
        <StatusUpdate
          request={request}
          handleRequestUpdate={handleRequestUpdate}
          handleCancel={handleCancel}
        />
      )
    case 'running':
      return (
        <UploadData
          request={request}
          handleUpdate={handleRequestUpdate}
          handleCancel={handleCancel}
        />
      )
    default:
      console.error('UpdateRequest | unknown form for action', action);
  }
}

type PropsT = {
  viewMode: string;
  request: RequestT;
  handleRequestUpdate: (id: number, updates: RequestUpdateT) => Promise<void>;
  closeDialog?: () => void;
}

const debug = false;
const UpdateRequest = (props:PropsT) => {
  let { viewMode, request, handleRequestUpdate} = props;
  debug && console.log('UpdateRequest | {viewMode, request.id request.status}:', viewMode, request.id, request.status);

  const _handleUpdate = async (id:number, updates: RequestUpdateT) => {
    await handleRequestUpdate(id, updates);
    props?.closeDialog && props.closeDialog();
  }

  return (
    <UserEditContainer>
      <RequestInfoHeader request={request} />
      { getForm(viewMode, request, _handleUpdate, props?.closeDialog) }
    </UserEditContainer>
  );
};

export default UpdateRequest;

const UserEditContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
