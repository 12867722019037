import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface IconPropsI {
  size?: number;
}

const CancelIcon = ({size = 24}: IconPropsI) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M12,23C5.9,23,1,18.1,1,12S5.9,1,12,1s11,4.9,11,11S18.1,23,12,23z M12,3c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9
	C21,7,17,3,12,3z"/>
        <path fill={colorHighlight} d="M14.5,7.4L12,9.9L9.5,7.4C8.9,6.8,8,6.8,7.4,7.4s-0.6,1.5,0,2.1L9.9,12l-2.5,2.5c-0.6,0.6-0.6,1.5,0,2.1
	c0.6,0.6,1.5,0.6,2.1,0l2.5-2.5l2.5,2.5c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1L14.1,12l2.5-2.5c0.6-0.6,0.6-1.5,0-2.1
	C16,6.8,15.1,6.8,14.5,7.4z"/>
      </svg>
    </>
  )
}

export default CancelIcon;