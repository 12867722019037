import React from 'react';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import { getValueByPath } from '@utils/misc';
import MenuButton from '@as_core/elements/MenuButton';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@as_core/icons/view.icon';
import {getShowDate, getStringHover} from "@subApps/requests/utils/renderValues";
import {PartnerAuthorizationT, PartnerT}  from "@subApps/requests/hooks/usePartners";
import TrashIcon from "@components/icons/trash.icon";
import {Item, Spacer} from "@subApps/requests/common/flexStyles";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";

const displayAuthorizations = (
  authorizations: string[],
  codeToName: {[key: string]: string}
) => {
  if (!authorizations) return '';
  const verbose = authorizations.map((code) => Object.hasOwn(codeToName, code) ? codeToName[code] : code);
  return (
    <Item>
      {authorizations.length }<Spacer /> <HoverInfoText text={verbose.toString()} />
    </Item>
  )
}

// Return the display value for the column from the AssayRequestForms
export const getDisplayValue = (
  col: BasicTableFieldsT,
  item: PartnerAuthorizationT | PartnerT,
  codeToName: {[key: string]: string},
  handleClick: (a:string, id:string) => void
) => {
  const id = Object.hasOwn(item, 'id') ? item['id'] : '';
  const value = getValueByPath(item, col.value, '');
  if (col.type === 'email') {
    return value;
  } else if (col.type === 'button') {
    if (col.value === 'edit') {
      return (
        <MenuButton
          onClick={() => handleClick('update', id)}
          icon={<EditIcon />}
          text={'Edit Record'}
          tooltipPlacement={'bottom'}
        />
      )
    } else if (col.value === 'view') {
      return (
        <MenuButton
          onClick={() => handleClick('view', id)}
          icon={<ViewIcon />}
          text={'View Full Record'}
          tooltipPlacement={'bottom'}
        />
      )
    } else if (col.value === 'delete') {
      return (
        <MenuButton
          onClick={() => handleClick('delete', id)}
          icon={<TrashIcon />}
          text={'Delete Record '}
          tooltipPlacement={'bottom'}
        />
      )
    }
  } else if (col.type === 'authorizations') {
    return displayAuthorizations(value, codeToName);
  } else {
    const value = getValueByPath(item, col.value, '');
    if (['date', 'datetime', 'time'].includes(col.type)) {
      return getShowDate(value, col.type);
    } else if (col.type === 'number') {
      return value.toString();
    } else {
      return getStringHover(value, col.width);
    }
  }
}

export const dataToTableRows = (
  items: PartnerAuthorizationT[] | PartnerT[],
  cols: BasicTableFieldsT[],
  codeToName: {[key: string]: string},
  handleClick: (t:string, u:string)=>void
) => {
  let rows = [];
  for (const item of items) {
    let row = {};
    for (const col of cols) {
      row[col.value] = getDisplayValue(col, item, codeToName, handleClick);
    }
    rows.push(row);
  }
  return rows;
};