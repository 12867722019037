import React from 'react';
import {CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material';
import useRequestForms from './useRequestForms';
import {getShowDate} from '../utils/renderValues';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import useRequests, { RequestT } from './useRequests';

export const StatusTableFields : BasicTableFieldsT[] = [
  { value: 'complete', label: '', type: '', width: 50 },
  { value: 'stage', label: 'Stage', type: 'string', width: 170 },
  { value: 'updated_at', label: 'Date Complete', type: 'date', width: 150 },
]

export type StatusT = {
  accountable: 'user' | 'partner';
  complete: boolean;
  updated: string;
}

const userAccountableStates: {[key: string]: string[]} = {
  'Screen' : ['platemapTemplateDownloaded', 'platemapAvailable', 'compoundsShipped'],
  'Consulting': ['open', 'meetingScheduled', 'meetingOccurred'],
};

export const StatusStates: {[key: string]: string[]} = {
  'Screen' : [
    'platemapTemplateDownloaded',
    'platemapAvailable',
    'compoundsShipped',
    'compoundsReceived',
    'assaysRunning',
    'dataProcessing',
    'dataQC',
    'dataDelivered',
    'complete'
  ],
  'Consulting': [
    'open',
    'meetingScheduled',
    'meetingOccurred',
    'completed'
  ]
}

export const StatusToLabel: {[key:string]: string} = {
    'platemapTemplateDownloaded': 'Plate Map Template Downloaded',
    'platemapAvailable' : 'Plate Map(s) Available',
    'compoundsShipped'  : 'Compounds Shipped',
    'compoundsReceived' : 'Compounds Received',
    'assaysRunning'     : 'Assays Running',
    'dataProcessing'    : 'Data Processing',
    'dataQC'            : 'Data QC',
    'dataDelivered'     : 'Data Delivered',
    'complete'          : 'Request Complete',
    'open'              : 'Open',
    'meetingScheduled'  : 'Meeting Scheduled',
    'meetingOccurred'   : 'Meeting Occurred',
    'completed'         : 'Completed',
}


export type RequestStatusT = {
  statusType: string;
  status: {[key: string]: StatusT}
}

export function getEmptyRequestStatus(requestCategory: string): RequestStatusT {
  const newStatus: RequestStatusT = {
    statusType: requestCategory,
    status: {}
  }
  if (Object.hasOwn(StatusStates, requestCategory)) {
    StatusStates[requestCategory].forEach(state => {
      newStatus.status[state] = {
        accountable: userAccountableStates[requestCategory].includes(state) ? 'user' : 'partner',
        complete: false,
        updated: ''
      }
    })
  }

  return newStatus;
}

const debug = false;
// useRequestStatus hook to make the UI cleaner
const useRequestStatus = () => {
  const {getRequestFormsByRequestIdOfType, createRequestForm, updateRequestForm} = useRequestForms();
  const {getRequestType} = useRequests();

  // get the request status for request id -- in case does not yet exist for new request -- create.
  const getRequestStatus = async (
    request: RequestT
  ): Promise<RequestStatusT> => {
    let statusForm: RequestStatusT = getEmptyRequestStatus(getRequestType(request).category);
    const forms = await getRequestFormsByRequestIdOfType(request.id, 'request_status');
    if (forms !== null && forms.length) {
      const matched = forms[0];
      statusForm = JSON.parse(matched.value);
    } else {
      const form = await createRequestForm({
        'name': `status for request ${request.id}`,
        'request_id': request.id.toString(),
        'form_type': 'request_status',
        'value': JSON.stringify(statusForm),
      });
      statusForm = JSON.parse(form.value);
    }
    // return the values
    return statusForm;
  }

  // handle update of stage -- always assumed to be set to true and set timestamp
  const updateRequestStatus  = async (
    request: RequestT,
    stage: string,
    completed: boolean = true
  ): Promise<RequestStatusT> => {
    if (debug) console.log('useRequestStatus | updateRequestStatus | requestId', request.id, 'stage', stage);
    const requestType = getRequestType(request).category;
    if (!StatusStates[requestType].includes(stage)) {
      console.error('useRequestStatus unknown stage', stage, ' -- no change');
      return getRequestStatus(request);
    }
    const statusForm = await getRequestFormsByRequestIdOfType(request.id, 'request_status');
    const requestFormId = Number(statusForm[0].id);
    let updatedStatus = await getRequestStatus(request);
    updatedStatus.status[stage].complete = completed;
    updatedStatus.status[stage].updated = new Date().toISOString();
    // update via api
    const requestForm = await updateRequestForm(
      requestFormId, {'value': JSON.stringify(updatedStatus)})
    if (debug) console.log('useRequestStatus | getRequestStatus | requestForm', requestForm);
    // return the values
    return JSON.parse(requestForm.value);
  }

  const getCountsFromStatus = (status: RequestStatusT): {[key: string]: number} => {
    const counts = {};
    if (!Object.hasOwn(StatusStates, status.statusType)) return counts;
    StatusStates[status.statusType].forEach(stage => {
      if (Object.hasOwn(status.status, stage)) {
        if (status.status[stage].complete) counts[stage] = 1;
      }
    })
    return counts;
  }

  const getStatusLabel = (stage: string): string => {
    if (!Object.hasOwn(StatusToLabel, stage)) return stage;
    return StatusToLabel[stage];
  }

  const getStatusToTableRows = (
    status: RequestStatusT
  ) => {
    let rows = [];
    if (!Object.hasOwn(StatusStates, status.statusType)) return rows;
    StatusStates[status.statusType].forEach(stage => {
      let row = {};
      for (const col of StatusTableFields) {
        if (col.value === 'complete') {
          row[col.value] =  status.status[stage].complete ? <CheckBox /> : <CheckBoxOutlineBlank />
        } else if (col.value === 'stage') {
          row[col.value] = getStatusLabel(stage);
        } else if (col.value === 'updated_at') {
          row[col.value] = getShowDate(status.status[stage].updated);
        } else {
          row[col.value] = '';
        }
      }
      rows.push(row);
    });
    // console.log('getStatusToTableRows', rows);
    return rows;
  };

  const getNextStatusStage = (
    status: RequestStatusT
  ) => {
    if (status === undefined) return null;
    let nextStage: string = null;
    StatusStates[status.statusType].forEach(stage => {
      if (Object.hasOwn(status.status, stage) && !status.status[stage].complete && nextStage === null) nextStage = stage;
    });
    return nextStage;
  };

  const isStageCompleted = (
    status: RequestStatusT,
    stage: string
  ) => {
    if (status === undefined) return false;
    if (Object.hasOwn(status.status, stage)) return status.status[stage].complete;
    return false;
  }

  return {
    getRequestStatus,
    getCountsFromStatus,
    getStatusLabel,
    getStatusToTableRows,
    getNextStatusStage,
    updateRequestStatus,
    isStageCompleted,
  };
};

export default useRequestStatus;
