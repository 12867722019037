import React from "react";
import styled from "styled-components/macro";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";

interface PropsI {
  obj: object
}

const ShowObject = (props:PropsI) => {
  const {obj} = props;
  if (obj == null) return null;

  return(
    <ObjectContainer>
      <Body>
        { Object.keys(obj).map((key: string, index) => (
          <Line key={index}>
            <Key>{key}:</Key>
            {obj[key].length < 50 ? <Value>{obj[key]}</Value> : <HoverTextTruncate text={obj[key]} characters={50}/>}
          </Line>
        ))}
      </Body>
    </ObjectContainer>
  )
}

export default ShowObject;

const ObjectContainer = styled.table`
`;

const Body = styled.tbody`
`;

const Line = styled.tr`
`

const Key = styled.td`
  font-size: 14px;
  text-align: right;
  padding-right: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
`

const Value = styled(Key)`
    text-align: left;
    color: ${(p) => p.theme.palette.textPrimary};
`