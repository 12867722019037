import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { DefaultTheme } from 'styled-components/macro';
import { THEME } from '@theme/AppStyles';

interface IThemeStyles {
  theme?: DefaultTheme;
}

export const MuiStyles = ({
  theme,
  children,
}: React.PropsWithChildren<IThemeStyles>) => {
  const [materialTheme, setMaterialTheme] = useState(
    createMuiTheme(theme || THEME.DEFAULT)
  );

  useEffect(() => {
    setMaterialTheme(createMuiTheme(theme || THEME.DEFAULT));
  }, [theme]);

  return <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>;
};

const createMuiTheme = (theme) => {
  return createTheme({
    typography: {
      fontFamily: 'Museo-Sans, Roboto, Verdana, sans-serif',
      fontSize: 14,
    },
    palette: {
      common: {
        black: '#000',
        white: '#FFF',
      },
      action: {
        active: theme.palette.accentPrimary,
        selected: theme.palette.accentPrimary,
      },
      background: {
        paper: theme.palette.backgroundTertiary,
        default: theme.palette.backgroundPrimary,
      },
      primary: {
        light: '#7986cb',
        main: '#3f51b5',
        dark: '#303f9f',
        contrastText: '#fff',
      },
      secondary: {
        light: theme.palette.accentTertiary,
        main: theme.palette.accentSecondary,
        dark: theme.palette.accentPrimary,
        contrastText: '#fff',
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },
      text: {
        primary: theme.palette.textPrimary,
        secondary: theme.palette.textSecondary,
        disabled: theme.palette.textTertiary,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            // Puts the label into notched position
            '.MuiInputLabel-outlined:has(+ .MuiOutlinedInput-root > .MuiOutlinedInput-input:-webkit-autofill)':
              {
                transform: 'translate(14px, -9px) scale(0.75)',
              },
            // Creates a hole around the text inside the border
            '.MuiOutlinedInput-input:-webkit-autofill + .MuiOutlinedInput-notchedOutline > legend':
              {
                maxWidth: '100%',
              },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {},
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            color: theme.palette.textSecondary,
            flexDirection: 'row-reverse',
            '&.Mui-expanded': {
              color: theme.palette.textPrimary,
            },
            '&.expandIconWrapper': {
              fontSize: '14px',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            '&.Mui-focused': {
              borderColor: theme.palette.backgroundTertiary,
            },
            margin: 0,
            padding: 0,
          },
          text: {
            color: theme.palette.textSecondary,
            ':hover': {
              color: theme.palette.textPrimary,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          label: {
            color: theme.palette.textPrimary,
            fontSize: theme.sizes.xsmall,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.backgroundQuaternary,
            fontSize: theme.sizes.xsmall,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            border: `1px solid ${theme.palette.textSecondary}`,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: theme.palette.textSecondary,
            fontSize: '12px',
            fontWeight: '300',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: theme.palette.accentPrimary,
              borderColor: theme.palette.accentSecondary,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.accentPrimary,
              },
            },
          },
          notchedOutline: {
            borderColor: theme.palette.accentSecondary,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: theme.palette.accentSecondary,
          },
          tooltip: {
            fontSize: theme.sizes.small,
            fontWeight: theme.weights.default,
            color: theme.palette.titlePrimary,
            backgroundColor: theme.palette.backgroundPrimary,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            li: {
              fontSize: '12px',
              color: theme.palette.textSecondary,
              '&:hover': {
                color: theme.palette.accentSecondary,
              },
            },
            '&::-webkit-scrollbar': {
              width: '7px', // Adjust the scrollbar width
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.accentPrimary, // Change the scrollbar thumb color
              borderRadius: '4px', // Adjust the scrollbar thumb border radius
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: theme.palette.accentSecondary, // Change the scrollbar thumb color on hover
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: theme.palette.accentSecondary,
            fontSize: '12px',
            padding: '2px 4px',
          },
          colorPrimary: {
            '&.Mui-checked': {
              // controls checked color for the thumb
              color: theme.palette.accentPrimary,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: '36px',
            height: '20px',
            padding: '5px 5px',
            '& .MuiSwitch-switchBase': {
              color: theme.palette.titleSecondary,
            },
            '& .MuiSwitch-thumb': {
              width: 11,
              height: 11,
              color: theme.palette.textSecondary,
            },
          },
          colorPrimary: {
            '&.Mui-checked': {
              // controls checked color for the thumb
              color: theme.palette.titleSecondary,
            },
          },
          track: {
            borderRadius: 20,
            opacity: 1.0,
            backgroundColor: theme.palette.backgroundQuaternary,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            color: theme.palette.textPrimary,
            fontSize: theme.sizes.xsmall,
            border: 'none !important',
          },
          outlined: {
            border: theme.borders.thin,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: theme.palette.textSecondary,
            '&$active': {
              color: theme.palette.textPrimary,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: theme.borders.thinLight,
          },
          head: {
            color: theme.palette.titleSecondary,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            color: theme.palette.textPrimary,
            fontSize: theme.sizes.xsmall,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: theme.palette.textSecondary,
            fontSize: theme.sizes.xsmall,
            '&.Mui-selected': {
              backgroundColor: theme.palette.backgroundSecondary,
              color: theme.palette.textPrimary,
              fontSize: theme.sizes.xsmall,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            li: {
              fontSize: '12px',
              color: theme.palette.textSecondary,
              '&:hover': {
                color: theme.palette.accentSecondary,
                background: theme.palette.backgroundQuaternary,
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            marginTop: 0,
            borderColor: theme.palette.backgroundQuaternary,
            borderWidth: 1,
            borderStyle: 'solid',
            '&::-webkit-scrollbar': {
              width: '7px', // Adjust the scrollbar width
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.accentPrimary, // Change the scrollbar thumb color
              borderRadius: '4px', // Adjust the scrollbar thumb border radius
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: theme.palette.accentSecondary, // Change the scrollbar thumb color on hover
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          track: {
            color: theme.palette.accentPrimary,
          },
          rail: {
            color: theme.palette.accentSecondary,
          },
        },
      },
    },
  });
};
