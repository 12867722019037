import React from 'react';
import styled from 'styled-components/macro';
import MenuButton from '@components/controls/MenuButton/MenuButton';
import {RequestT} from "@subApps/requests/hooks/useRequests";
import ViewIcon from "@as_core/icons/view.icon";
import {UserEmailDictionaryT} from "@subApps/requests/hooks/useUserEmails";
import {RequestDetailsFields} from "@subApps/requests/utils/tableDefinitions";
import {getRequestDetailsDisplayValue} from "@subApps/requests/utils/renderValues";
import EditIcon from "@mui/icons-material/Edit";
import {getDaysSinceDate} from "@utils/misc";
import useRequestStatus from "@subApps/requests/hooks/useRequestStatus";
import {useQuery} from "@tanstack/react-query";

interface PropsI {
  viewMode?: string;
  request: RequestT;
  handleClick: (t: string, id: number) => void;
  userEmails: UserEmailDictionaryT;
}

const debug = false;
const RequestItemTile = (props: PropsI) => {
  const { viewMode = 'partners', request, handleClick, userEmails } = props;
  const { getRequestStatus } = useRequestStatus();

  const { isPending, isError, error, data: requestStatus } = useQuery({
    queryKey: ['requestStatus', request.id],
    queryFn: () => getRequestStatus(request),
  })
  if (isError) console.error(error.message);

  if (debug) console.log('RequestProcessDetails | request.id', request.id,
    'react-query: ', isPending, error, requestStatus);
  if (debug) console.log('RequestItemTile | {props}:', props);

  return (
    <CartItemTileContainer>
      <ActionsBarContainer>
        <MenuButton
          onClick={() => handleClick('view', request.id)}
          icon={<ViewIcon />}
          text={'View Full Assay Request'}
          tooltipPlacement={'bottom'}
        />
        <MenuButton
          onClick={() => handleClick('edit', request.id)}
          icon={<EditIcon/>}
          text={'Update Assay Request'}
          tooltipPlacement={'bottom'}
        />
      </ActionsBarContainer>
      <ElapsedTimeContainer color={'green'}>
        { isPending ? null: getDaysSinceDate(request.updated_at)} elapsed days since last update
      </ElapsedTimeContainer>
      {
        RequestDetailsFields[viewMode].map((item, index) =>
          getRequestDetailsDisplayValue(request, item, index, userEmails, '250px'))
      }
    </CartItemTileContainer>
  );
};

export default RequestItemTile;

const CartItemTileContainer = styled.div`
  display: flex;
  position: relative;
  width: max-content;
  height: max-content;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.textSecondary };
`;

const ActionsBarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`

const ElapsedTimeContainer = styled.div<{color: string}>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${(p) => p.color};
`
