import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import { Person } from '@mui/icons-material';
import {UserT} from "@as_core/contexts/UserContext";

export const accountMenuItems: MenuItemsT[] = [
  {
    id: 'u.user',
    name: 'Manage My Profile',
    pathname: '/account/user',
    icon: <Person />
  },
  {
    id: 'u.preferences',
    name: 'Manage My Preferences',
    pathname: '/account/preferences',
    icon: <SettingsIcon />
  },
];

const debug = false;
export const getAccountMenus = (user: UserT, subscription: string) => {
  if (debug) console.log('getMenuAccount | subscription', subscription);
  return accountMenuItems;
}


