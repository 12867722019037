import React from 'react';
import styled from 'styled-components/macro';
import {UserEmailDictionaryT} from '../hooks/useUserEmails';
import { RequestT } from '../hooks/useRequests';
import RequestItemTile from "@subApps/requests/partners/components/RequestItemTile";

interface PropsI {
  stage: string;
  requests: RequestT[];
  handleClick: (t: string, id: number) => void;
  userEmails: UserEmailDictionaryT;
}

const debug = false;
const RequestStageTiles = (props: PropsI) => {
  const { stage, requests, handleClick, userEmails } = props;
  debug && console.log('partners/RequestsTable | {stage, requests}:', stage, requests);

  if (!requests || requests.length === 0) {
    return null;
  }

  return (
    <RequestTilesContainer>
      {
        requests.map((request, index) => (
          <RequestItemTile
            key={`request_tile_${request.id}_${index}`}
            request={request}
            handleClick={handleClick}
            userEmails={userEmails}
          />
        ))
      }

    </RequestTilesContainer>
  );
};

export default RequestStageTiles;

const RequestTilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 8px 8px;
`;

