import { handleError } from '@utils/misc';
import useCognito from '@as_core/account/useCognito';
import { AxiosResponse } from 'axios';
import { auth3RnDClient as authClient, authCoreClient, authServicesClient } from '@utils/api/base';

const ApiInfo = {
  appGet: (token) => authClient(token).get('/info/'),
  coreGet: (token) => authCoreClient(token).get('/info/'),
  servicesGet: (token) => authServicesClient(token).get('/info/'),
};

// ###########################################################################
// TYPES
// ###########################################################################
interface InfoUserT {
  id: string;
  orgId: string;
  user: string;
}

interface InfoApiT {
  build_id: string;
  build_run_type: string;
  build_type: string;
  description: string;
  title: string;
  version: string;
}

interface InfoDBT {
  organization: string;
  owner: string;
  type: string;
  version: string;
}

export interface ApiInfoT {
  user?: InfoUserT;
  api?: InfoApiT;
  db?: InfoDBT;
}

export interface ApiInfoResponseT {
  errors: any[];
  info: ApiInfoT;
}

export type ApiInfoAllT = {
  [id: string]: ApiInfoT;
}

export const useApiInfo = () => {
  const {getToken} = useCognito();

  async function getInfo (api): Promise<ApiInfoT> {
    try {
      let resp: AxiosResponse<ApiInfoResponseT>;
      if (api === 'App') {
        resp = await ApiInfo.appGet(getToken());
      } else if (api === 'Core') {
        resp = await ApiInfo.coreGet(getToken());
      } else if (api === 'Services') {
        resp = await ApiInfo.servicesGet(getToken());
      } else {
        console.error(`Unknown API ${api}`);
        return null;
      }
      const info = resp.data.info;
      if (resp.data.errors.length > 0) console.error(`getApiInfo ${api} ERRORS`, resp.data.errors);
      return info;
    } catch (err) {
      handleError(err);
      return null;
    }
  }

  const getApiInfo = async (api: string): Promise<ApiInfoT> => {
    if (['App', 'Core', 'Services'].includes(api)) {
      return await getInfo(api);
    } else {
      console.error(`Unknown API ${api}`);
    }
    return null;
  };

  const getApiInfoAll = async (): Promise<ApiInfoAllT> => {
    let allInfo: ApiInfoAllT = {};
    const promises = ['App', 'Core', 'Services'].map(async (api) => {
      allInfo[api] = await getApiInfo(api);
    });
    await Promise.all(promises);
    return allInfo;
  }

  const getVersionLabel = async () : Promise<string> => {
    const apiInfo = await getApiInfo('App');
    const ui = process.env.REACT_APP_HOST_TYPE;
    return ui.concat('|',
      apiInfo?.api?.version, ':',
      apiInfo?.api?.build_id, '|',
      apiInfo?.db?.type,':',
      apiInfo?.db.version
    );
  }

  return {
    getApiInfo,
    getApiInfoAll,
    getVersionLabel
  }
}