import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

import Chip from './Chip';

export type DropdownOptionsT = {
  value: string;
  label: string;
}

interface PropsI {
  id: string;
  label: string;
  values: DropdownOptionsT[];
  selectedIds: string[];
  maxAllowed: number;
  onSelect: (id:string)=>void,
  onUnselect: (id:string)=>void,
  onReset: ()=>void,
}

const MultiSelectDropdown = (props:PropsI) => {
  const {
    id = 'primary',
    label = 'Primary target',
    values = [],
    selectedIds = [],
    maxAllowed = 8,
    onSelect,
    onUnselect,
    onReset
  } = props;
  const containerRef = useRef();
  const [chips, setChips] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [hoveredSuggestion, setHoveredSuggestion] = useState(0);
  const [inputValue, setInputValue] = useState('');

  function handleSuggestionSelect(id: string) {
    if (selectedIds.includes(id)) {
      onUnselect(id);
    } else {
      if (selectedIds.length >= maxAllowed) {
        onUnselect(selectedIds[0]);
      }
      onSelect(id);
    }
    handleHideSuggestions();
  }

  function handleSuggestionUnselect(id:string) {
    onUnselect(id);
  }

  function handleAddClick() {
    setShowSuggestions(prev => !prev);
  }

  function handleShowSuggestions() {
    setHoveredSuggestion(0);
    setShowSuggestions(true);
  }

  function handleHideSuggestions() {
    setShowSuggestions(false);
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (['Esc', 'Escape'].includes(e.key)) {
      setShowSuggestions(false);
    }
    if (!showSuggestions) return;
    if (e.key === 'ArrowUp') {
      setHoveredSuggestion((state) => Math.max(state - 1, 0));
    } else if (e.key === 'ArrowDown') {
      setHoveredSuggestion((state) =>
        Math.min(state + 1, suggestions.length - 1)
      );
    }
  }

  useOnClickOutside(containerRef, handleHideSuggestions);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setSuggestions(values.filter((d) => d.label.toLowerCase().includes(inputValue.toLowerCase())));
  }, [inputValue]);

  useEffect(() => {
    setInputValue('');
  }, [showSuggestions]);

  useEffect(() => {
    setChips(values.filter((d) => selectedIds.includes(d.value)));
  }, [values, selectedIds]);

  return (
    <Container ref={containerRef}>
      <Label>
        <span>{label}</span>
        {chips.length > 0 && <button onClick={()=>onReset()}>Reset</button>}
      </Label>
      <Field>
        <Chips>
          {chips.map((d) => (
            <Chip
              key={d.value}
              id={d.value}
              name={d.label}
              onClick={()=>handleSuggestionUnselect(d.value)}
            />
          ))}
          <Chip key={'add'} id={'+'} name={'+'} onClick={handleAddClick}/>
        </Chips>
      </Field>
      {showSuggestions && (
        <Suggestions>
          <Input
            id={id}
            type='text'
            placeholder='Search...'
            autoComplete='off'
            value={inputValue}
            onFocus={handleShowSuggestions}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <SuggestionList>
            {suggestions.map((d, i) => (
              <SuggestionListItem
                key={d.value}
                active={hoveredSuggestion !== null && hoveredSuggestion === i}
                selected={selectedIds.includes(d.value)}
                onMouseEnter={() => setHoveredSuggestion(i)}
                onMouseLeave={() => setHoveredSuggestion(null)}
                onClick={() => handleSuggestionSelect(d.value)}
              >
                {d.label}
              </SuggestionListItem>
            ))}
          </SuggestionList>
        </Suggestions>
      )}
    </Container>
  );
};

export default MultiSelectDropdown;

const Container = styled.div`
  position: relative;
  width: 275px;
  user-select: none;
  & * {
    box-sizing: border-box;
  }
`;

export const Label = styled.label`
  display: flex;
  height: 24px;
  align-items: baseline;
  justify-content: space-between;
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: 14px;
  cursor: pointer;
`;

const Field = styled.div`
  position: relative;
  width: calc(100% - 3px);
  height: auto;
  max-height: 135px;
  cursor: pointer;
  background-color: ${(p) => p.theme.palette.backgroundPrimary };
  overflow-y: auto;
`;

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 3px;
  border: 1px solid ${(props) => props.theme.palette.titlePrimary};
  border-radius: 5px;
  cursor: pointer;
  overflow-x: scroll;
`;

const Suggestions = styled.div`
  position: absolute;
  left: 0;
  z-index: 10000;
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.titleSecondary};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
`;

const Input = styled.input`
  width: 100%;
  min-height: 40px;
  max-height: 100px;
  padding: 0 5px;
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: ${(props) => props.theme.sizes.small};
  font-weight: ${(props) => props.theme.weights.default};
  border: none;
  outline: none;
  background: none;
`;

const SuggestionList = styled.ul`
  width: 100%;
  max-height: 250px;
  list-style-type: none;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const SuggestionListItem = styled.li<{active: boolean, selected: boolean}>`
  width: 100%;
  font-size: 12px;
  line-height: 19px;
  padding: 0 5px;
  color: ${(props) => props.theme.palette.titlePrimary};
  cursor: pointer;

  ${(p) =>
    p.active &&
    `
    background-color: ${p.theme.palette.accentSecondary};
  `}

  ${(p) =>
    p.selected &&
    `
    color: ${p.theme.palette.titlePrimary};
    background-color: ${p.theme.palette.accentPrimary};
  `}
`;
