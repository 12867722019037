import React from 'react';
import styled from 'styled-components/macro';

interface PropsI {
  title: string;
  width?: string;
  height?: string;
  children?: React.ReactNode;
}

const debug = false;
const CategoryContainer = (props: PropsI) => {
  const {title, width='max-content', height='max-content'} = props;
  if (debug) console.log('CategoryContainer | props:', props);

  return (
    <BorderedContainer width={width} height={height}>
      <Header>{title}</Header>
      <Body>{ props.children }</Body>
    </BorderedContainer>
  );
};

export default CategoryContainer;

export const BorderedContainer = styled.div<{width: string, height: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  background: ${(p) => p.theme.palette.backgroundPrimary};
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  margin: 10px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  background-color: ${(p) => p.theme.palette.accentSecondary};
  color: #000129;
`;

const Body = styled.div`
  display: flex;
`





